import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-volunteer-edit',
  templateUrl: './volunteer-edit.component.html',
  styleUrls: ['./volunteer-edit.component.css']
})
export class VolunteerEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  volunteer_data={date:0,
    postion_en:null,postion_ar:null,
    department_en:null,department_ar:null,
    duration_en:null,duration_ar:null,
    location_en:null,location_ar:null,
    report_en:null,report_ar:null,
    kind:null,
  
    body_en:null,body_ar:null,
    img_url:null,img_name:null,key:null, 
    }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.volunteer) {
    this.router.navigate(['/admin/volunteer/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.volunteer_data=VariablesService.volunteer
    this.get_section()
  }
  init_upload(){
    if( this.volunteer_data.postion_en  && this.volunteer_data.postion_ar &&
      this.volunteer_data.department_en  && this.volunteer_data.department_ar &&
      this.volunteer_data.duration_en  && this.volunteer_data.duration_ar &&
      this.volunteer_data.location_en  && this.volunteer_data.location_ar &&
      this.volunteer_data.report_en  && this.volunteer_data.report_ar &&
      this.volunteer_data.body_en  && this.volunteer_data.body_ar
       ){
      
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
  upload(){
    this.time_now = Date.now()
    
    this.volunteer_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('volunteer'); //new
    Ref_data.child(this.volunteer_data.key).set(this.volunteer_data).then(()=>{
      this.toastr.success('Upload finished, The volunteer added successfully. ')
  
      this.router.navigate(['/admin/volunteer/list'])
     
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

  section_list=[]
  get_section(){
    this.section_list=['volunteer','internship']
  //   this.spinner=true
  //   firebase.database().ref('program').once('value',snapshots=>{
  //   snapshots.forEach(snapshot=>{
  // let y = snapshot.val()
  // var date1 = new Date(y.date);
  // y.date=  date1.toLocaleString()
  
  // y.key = snapshot.key
  // this.section_list.push(y)
  //   })
  // })
  }
}
