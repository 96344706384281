import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { VariablesService } from './variables.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router : Router,private toastr: ToastrService,){}
  canActivate(): Promise<boolean >  {
    //  let user_f = JSON.parse(localStorage.getItem('user'))
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user =>{
        if(user){
          VariablesService.user=user
       
       
                return resolve(true)
           
        
        //   var currentTime=Math.floor(Date.now()/1000);
        //   let current_user=  localStorage.getItem('user_email');
        //   let ref =firebase.database().ref('logs/')
        //  let user_log={user:current_user,date:currentTime,action:'log_in',description:"log_in"}
        // ref.push(user_log)
        }else{
          
    
          this.toastr.error('you are not logging in' )
          this.router.navigate(['/login'])
          
        }
       
      })
    })
 
  }
  
}
