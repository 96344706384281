import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-sycac',
  templateUrl: './admin-sycac.component.html',
  styleUrls: ['./admin-sycac.component.css']
})
export class AdminSycacComponent implements OnInit {

  data_check:boolean=false
  time_now
  pdf_file
  file_name
  file
  file_s:boolean=false
  uploadProgress_pdf
uploadProgress_00_pdf

  douzan_block_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
   }
   pdf_url
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
  
    this.read_data()
   
  }

  read_data(){
    let Ref_data = firebase.database().ref('template/douzan_block'); 
    Ref_data.once('value',snapshot=>{
      if(snapshot.exists()){
        this.douzan_block_data=snapshot.val()
      }
 
    }).then(()=>{
      let ref11 = firebase.database().ref('template').child('profile')
      ref11.once('value',snapshot=>{
        this.pdf_url=snapshot.val()
      })
    })
  }
  init_upload(){
    if(this.douzan_block_data.name_en && this.douzan_block_data.name_tr && this.douzan_block_data.name_ar 
      && this.douzan_block_data.description_en && this.douzan_block_data.description_tr && this.douzan_block_data.description_ar
 
       ){
      
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }

 
  upload(){
    
    this.time_now = Date.now()

    this.douzan_block_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('template/douzan_block'); 
    Ref_data.set(this.douzan_block_data).then(()=>{
      this.toastr.success('Upload finished, The douzan_block added successfully. ')
    
  
   
    })
    
    // ).catch(error=>{
    //   console.log(error.message)
    // })

  }

  init_file(event){
    this.file=null
    const element = event.target.files[0];
    this.file_name=element.name
    this.file=element
    this.file_s=true
  }
  pdf_upload(){
    if(this.file_s){
      
    let id = "uploading_pdf_window"
    let go = document.getElementById(id)
    go.click()

      let storageRef = firebase.storage().ref('template').child('profile')
      var uploadTask = storageRef.child('douzan-profile').put(this.file)
    
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      snapshot=> {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      this.uploadProgress_pdf = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.uploadProgress_00_pdf= Math.floor(this.uploadProgress_pdf)
      switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('Upload is running');
        break;
      }
      }, (error=> {
      
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.message) {
      case 'storage/unauthorized':
      // User doesn't have permission to access the object
      break;
      
      case 'storage/canceled':
      // User canceled the upload
      break;
      
      
      
      case 'storage/unknown':
      // Unknown error occurred, inspect error.serverResponse
      break;
      }
      }), (()=> {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL=> {
      
    //  this.part_a.img_url=downloadURL
     let ref = firebase.database().ref('template').child('profile')
      ref.set(downloadURL).then(()=>{
        this.file_s=false
        this.toastr.success('Upload finished, The Profile added successfully. ')
        let id = "close_window"
        let go = document.getElementById(id)
        go.click()
        this.read_data()
      })
    
      
      }));
      }))
    }else{
      this.toastr.success('You shoud add new file first. ')
    }
   
  
  }

  open_pdf(){
    window.open(this.pdf_url, "_blank")
  }
  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

}
