import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-news-edit',
  templateUrl: './admin-news-edit.component.html',
  styleUrls: ['./admin-news-edit.component.css']
})
export class AdminNewsEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  news_data={date:0,name_en:null,name_ar:null,subtitle_en:null,subtitle_ar:null,home_page:false,news_section:false,active:false,order:1,
    excerpt_en:null,excerpt_ar:null,
    body_en:null,body_ar:null,
    caption_en:null,caption_ar:null,
    img_url:null,img_name:null,key:null, img_url_0:null,
    style:{ bg:'#ffffff',color:'#000000',logo:true}}
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.news) {
    this.router.navigate(['/admin/news/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.news_data=VariablesService.news
  
  }
  init_upload(){
    if(this.news_data.name_en && this.news_data.name_ar 
    
      && this.news_data.excerpt_en &&  this.news_data.excerpt_ar
      &&  this.news_data.body_en &&  this.news_data.body_ar
      && this.news_data.img_url && this.news_data.img_url_0){
      
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
  upload(){
    this.time_now = Date.now()
    
    this.news_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('news'); //new
    Ref_data.child(this.news_data.key).set(this.news_data).then(()=>{
      this.toastr.success('Upload finished, The news added successfully. ')
  
      this.router.navigate(['/admin/news/list'])
     
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


}
