import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';

@Component({
  selector: 'app-sycac-mission',
  templateUrl: './sycac-mission.component.html',
  styleUrls: ['./sycac-mission.component.css']
})
export class SycacMissionComponent implements OnInit {
  sycac_block_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
   }
  data={en_description:null,tr_description:null,ar_description:null}
  ref=firebase.database().ref("about-sycac").child("mission")
  constructor(protected sanitizer: DomSanitizer) {
    this.read()
    this.get_sycac_data()
   }

  ngOnInit(): void {
    this.get_lang()
   
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.about_sycac

}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
 
  read(){
    this.ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.data=snapshot.val()
        this.data.en_description= this.transform(this.data.en_description)
        this.data.tr_description= this.transform(this.data.tr_description)
        this.data.ar_description= this.transform(this.data.ar_description)      }
 
    })
  }

  get_sycac_data(){
    let ref = firebase.database().ref('template/sycac_block')
    ref.once('value',snapshots=>{
      this.sycac_block_data=snapshots.val()
    })
  }
}
