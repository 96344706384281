import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { Toast, ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-artist-kind-list',
  templateUrl: './artist-kind-list.component.html',
  styleUrls: ['./artist-kind-list.component.css']
})
export class ArtistKindListComponent implements OnInit {
  main_s_data={title_en:null,title_ar:null , items:0,slug:null,name:null}
  main_s=[]
  office_m_list=['control','power','accessories']
  constructor(private toastr: ToastrService,private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.read()
  }
  save(){
    for(let  index of this.main_s){
      index.slug =  index.title_en 
      index.name =  index.title_en 
    }
    let ref = firebase.database().ref('main_data').child('artist_kind')
    ref.set(this.main_s).then(()=>{
      this.toastr.success('The new data was saved successfully')
    })
  }
  read(){
    let ref = firebase.database().ref('main_data').child('artist_kind')
    ref.once('value',snapshot=>{
      if(snapshot.val()){
        this.main_s=snapshot.val()

      }
    })
  }
  add_main_s(){
   
    this.main_s.push(this.main_s_data)
    this.main_s_data={title_en:null,title_ar:null , items:0,slug:null,name:null}
    
  }
  remove_main_s(element){
    let dsData = this.main_s;
    const itemIndex = dsData.findIndex(obj => obj['name'] === element.name);
    this.main_s.splice(itemIndex, 1);
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }


  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  delete_data
  init_delete(event){
    this.delete_data=null
    let id = "delete_plane_window"
    let go = document.getElementById(id)
    go.click()
    this.delete_data=event
  
  }
}
