import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.css']
})
export class EventAddComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  event_data={date:0,name_en:null,name_ar:null,
    
    event_link:null,
    start_date:0,end_date:0,
    start_time:0,end_time:0,
    sub_title_en:null,sub_title_ar:null,
  
    body_en:null,body_ar:null,
   
    img_url:null,img_name:null,
   
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.get_section()
  }

  init_upload(){
 
    if( 
      this.event_data.name_en && this.event_data.name_ar  &&
      this.event_data.sub_title_en && this.event_data.sub_title_ar  &&
      this.event_data.body_en && this.event_data.body_ar  &&
      this.event_data.start_date && this.event_data.end_date  &&
      this.event_data.start_time && this.event_data.end_time 
      && this.event_data.img_url && this.event_data.event_link ){
        this.upload()
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }


  upload(){
    this.time_now = Date.now()
    
    this.event_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('event'); //new
    Ref_data.push(this.event_data).then(()=>{
      this.toastr.success('Upload finished, The event added successfully. ')
  
    this.router.navigate(['/admin/event/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


  section_list=[]
  get_section(){
    this.section_list=['donors','partners']
  //   this.spinner=true
  //   firebase.database().ref('program').once('value',snapshots=>{
  //   snapshots.forEach(snapshot=>{
  // let y = snapshot.val()
  // var date1 = new Date(y.date);
  // y.date=  date1.toLocaleString()
  
  // y.key = snapshot.key
  // this.section_list.push(y)
  //   })
  // })
  }


}
