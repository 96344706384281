import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    this.read()
  }
  tag=null
  tag_array=[]
  add_tag(){

  
  this.tag_array.push(this.tag)
  this.tag=null
    
  }

  remove_tag(event){
    let i=0
     for(let index of this.tag_array){
   
       if(index === event){
        this.tag_array.splice(i,1)
       
        
       }
       i=i+1
     }
    }

 

      tag_ar=null
      tag_ar_array=[]
      add_tag_ar(){
    
      
      this.tag_ar_array.push(this.tag_ar)
      this.tag_ar=null
        
      }
    
      remove_tag_ar(event){
        let i=0
         for(let index of this.tag_ar_array){
       
           if(index === event){
            this.tag_ar_array.splice(i,1)
           
            
           }
           i=i+1
         }
        }


     
    read(){
      let ref = firebase.database().ref('seo')
      ref.once('value',snapshots=>{
        if(snapshots.exists()){
          this.tag_array= snapshots.val()

        }
      })
    
      let ref2 = firebase.database().ref('seo_ar')
      ref2.once('value',snapshots=>{
        if(snapshots.exists()){
          this.tag_ar_array= snapshots.val()

        }
      })
    
      
    }
    save(){
      let ref = firebase.database().ref('seo')
      ref.set(this.tag_array).then(()=>{
        this.toastr.success('The data saved successfully')
      })
    }
    
    save_ar(){
      let ref = firebase.database().ref('seo_ar')
      ref.set(this.tag_ar_array).then(()=>{
        this.toastr.success('The data in arabic saved successfully')
      })
    }
 
  
}
