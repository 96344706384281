import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-project-edit',
  templateUrl: './admin-project-edit.component.html',
  styleUrls: ['./admin-project-edit.component.css']
})
export class AdminProjectEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  project_data={date:0,name_en:null,name_ar:null,subtitle_en:null,subtitle_ar:null,program:null,active:false,order:1,
    excerpt_en:null,excerpt_ar:null,
    body_en:null,body_ar:null,
    caption_en:null,caption_ar:null,
    img_url:null,img_name:null,key:null, img_url_0:null,
    style:{ bg:'#ffffff',color:'#000000',logo:true}}
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.project) {
    this.router.navigate(['/admin/projects/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.project_data=VariablesService.project
    this.get_section()
  }
  init_upload(){
    if(this.project_data.name_en && this.project_data.name_ar && this.project_data.program
      && this.project_data.subtitle_en &&  this.project_data.subtitle_ar
      && this.project_data.excerpt_en &&  this.project_data.excerpt_ar
      &&  this.project_data.body_en &&  this.project_data.body_ar
      && this.project_data.img_url && this.project_data.img_url_0){
      
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
  upload(){
    this.time_now = Date.now()
    
    this.project_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('project'); //new
    Ref_data.child(this.project_data.key).set(this.project_data).then(()=>{
      this.toastr.success('Upload finished, The project added successfully. ')
  
      this.router.navigate(['/admin/projects/list'])
     
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

  section_list=[]
  get_section(){
    this.section_list=[]
    this.spinner=true
    firebase.database().ref('program').once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  let y = snapshot.val()
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleString()
  
  y.key = snapshot.key
  this.section_list.push(y)
    })
  })
  }
}
