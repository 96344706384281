import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-staff-add',
  templateUrl: './admin-staff-add.component.html',
  styleUrls: ['./admin-staff-add.component.css']
})
export class AdminStaffAddComponent implements OnInit {

  data_check:boolean=false
  time_now
 
  job_data={date:0,name_en:null,name_ar:null,
    description_en:null,description_ar:null,
    start_date:null,end_date:null,start_date_t:null,end_date_t:null,location:null,
    body_en:null,body_ar:null,
    }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    
  }

  init_upload(){
    if(this.job_data.name_en && this.job_data.name_ar 
      && this.job_data.description_en  && this.job_data.description_ar
      &&  this.job_data.start_date && this.job_data.end_date && this.job_data.location
      &&  this.job_data.body_en  && this.job_data.body_ar
     ){
        this.upload()
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
 

  upload(){
    let date_start:any = this.job_data.start_date;
    let date_end:any = this.job_data.end_date;
    this.job_data.start_date_t = Date.parse(date_start)/1000
    this.job_data.end_date_t = Date.parse(date_end)/1000
    this.time_now = Date.now()
   

    // Upload completed successfully, now we can get the download URL

    
  
    this.job_data.date=this.time_now
    
    
    
    let Ref_data = firebase.database().ref('jobs'); //new
    Ref_data.push(this.job_data).then(()=>{
      this.toastr.success('Upload finished, The job added successfully. ')
     
    this.router.navigate(['/admin/join-us-staff/list'])
   
    })
    
   
  
  }
  



  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

}
