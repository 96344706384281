import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { LanguagesService } from 'src/app/services/languages.service';
import firebase from 'firebase';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    pdf_url
    totops:boolean=false
    topPosToStartaddlist = 280;
    contact_list={twitter:null,map:null,'whatsapp':null,'facebook':null,instagram:null,youtube:null,skype:null,phone1:null,phone2:null,phone3:null,
    phone4:null,phone1_title:null,phone2_title:null,phone3_title:null,phone4_title:null,email:null,location:null}
    constructor(public location: Location, private router: Router) {
      this.getScreenSize()
        this.get_lang()
        this.get_programs()
        this.get_projects()
        this.get_progile()
        this.read()
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
               if (event.url != this.lastPoppedUrl)
                   this.yScrollStack.push(window.scrollY);
           } else if (event instanceof NavigationEnd) {
               if (event.url == this.lastPoppedUrl) {
                   this.lastPoppedUrl = undefined;
                   window.scrollTo(0, this.yScrollStack.pop());
               } else
                   window.scrollTo(0, 0);
           }
         });
         this.location.subscribe((ev:PopStateEvent) => {
             this.lastPoppedUrl = ev.url;
         });

         
    }

    ngOnInit() {
      this.getScreenSize()
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }

    langstatus
    dir
    lang_view
    language
   get_lang(){
    this.langstatus=localStorage.getItem('language')
    this.dir=localStorage.getItem('dir')
    this.language=LanguagesService.language.navbar
    switch (this.langstatus) {
      case 'en':
        this.lang_view="English"
        break;
        case 'ar':
          this.lang_view="العربية"
          break;
            case 'tr':
              this.lang_view="Turkçe"
              break;
    }
  }
  
  en(){
    // AppComponent.lang=AppComponent.langen
     localStorage.setItem('language','en')
     localStorage.setItem("dir","ltr")
     location.reload()
    
   }
   ar(){
    // AppComponent.lang=AppComponent.langen
     localStorage.setItem('language','ar')
     localStorage.setItem("dir","rtl")
     location.reload()
    
   }
   tr(){
    // AppComponent.lang=AppComponent.langen
     localStorage.setItem('language','tr')
     localStorage.setItem("dir","ltr")
     location.reload()
    
   }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    go_program(event){
        VariablesService.program=event
        this.router.navigate(['/program/'+event.acronym])
    }
    go_project(event){
      VariablesService.project=event
     
      this.router.navigate(['project/'+event.name_en])

      
    }

    programs_list=[]
    get_programs(){
      this.programs_list=[]
    let Ref_data = firebase.database().ref('programs')
   Ref_data.once('value',snapshots=>{
      snapshots.forEach(snapshot=>{
    let y = snapshot.val()
    var date1 = new Date(y.date);
    y.date=  date1.toLocaleString()
    
    y.key = snapshot.key
    this.programs_list.push(y)
      })
    }).then(()=>{
      this.programs_list.reverse()
    })
    }
    
    projects_list=[]
    get_projects(){
      this.projects_list=[]
    let Ref_data = firebase.database().ref('projects')
   Ref_data.once('value',snapshots=>{
      snapshots.forEach(snapshot=>{
    let y = snapshot.val()
    var date1 = new Date(y.date);
    y.date=  date1.toLocaleString()
    
    y.key = snapshot.key
    this.projects_list.push(y)
      })
    }).then(()=>{
      this.projects_list.reverse()
    })
    }
    get_progile(){
      let ref11 = firebase.database().ref('template').child('profile')
          ref11.once('value',snapshot=>{
            this.pdf_url=snapshot.val()
          })
    }
    open_profile(){
      window.open(this.pdf_url, "_blank");
    
    }

    @HostListener('window:scroll')
   
    checkScroll() {
  
     
      // window의 scroll top
      // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
  ////console.log(document.documentElement.offsetHeight,'one')
  ////////console.log( window.innerHeight)
  ////console.log(window.pageYOffset)
  ////console.log( window.innerHeight+ document.documentElement.scrollTop + this.topPosToStartaddlist)
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
   
    
     ////console.log(scrollPosition)
     if (scrollPosition    > this.topPosToStartaddlist ) {
     
      this.totops=true
      
       } else{
         this.totops=false
       }
      if (document.documentElement.offsetHeight    <= window.innerHeight + document.documentElement.scrollTop + this.topPosToStartaddlist ) {
    
      } else{
       
      }
    }
    screenWidth: number;
    st600:boolean=false
    screenHeight: number;
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          //console.log(this.screenHeight, this.screenWidth);
          if(this.screenWidth <1000){
this.st600=true
          }else{
            this.st600=false
          }
    }
    read(){
      let ref = firebase.database().ref('contact-us')
     ref.once('value',snapshot=>{
        if(snapshot.exists()){
          this.contact_list=snapshot.val()
        
        }
   
      })
    }
}
