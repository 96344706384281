import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  url1
  url2
  url3

  sycac_block_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
   }
  constructor() {

   }
  
  ngOnInit(): void {
    this.get_lang()
    this.get_slide()
    this.get_sycac_data()
    
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}
  get_slide(){
    let ref = firebase.database().ref('template/slide-show')
    ref.once('value',snapshots=>{
     this.url1= snapshots.val().img_1
     this.url2= snapshots.val().img_2
     this.url3= snapshots.val().img_3
    })
  }
  get_sycac_data(){
    let ref = firebase.database().ref('template/sycac_block')
    ref.once('value',snapshots=>{
      this.sycac_block_data=snapshots.val()
    })
  }
}
