import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  constructor() { }
  public static language
  public static slider_imgs
  public static story
  public static script
  public static resource
  public static event
  public static sponsor
  public static press
  public static volunteer
  public static career
  public static news
  public static about_section
  public static member
  public static section  
  public static home  
  public static join_support  
  public static album
  public static artist
  public static job
  public static testimonial
  public static user
  public static partner
  public static  project
  public static  program
  public static place
  public static success

}
