import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList
} from "@angular/core";
import firebase from "firebase";
import { LanguagesService } from "src/app/services/languages.service";

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.css']
})
export class CountersComponent implements OnInit {

  nums: Array<number> = [25, 76, 48];

  @ViewChild("oneItem") oneItem: any;
  @ViewChildren("count") count: QueryList<any>;
  // @ViewChild("count", { static: true }) count : QueryList<any>;
  // @ViewChild("oneItem", { static: true }) oneItem : ElementRef;

  data_numbers={targeted_people:0,work_areas:0,implemented_projects:0,number_exercises:0,dialogue_sessions:0,
    forums:0,targeted_women:0,special_needs:0}
  constructor(private elRef: ElementRef) {}
  main_s=[]
  ngOnInit() {
    this.read()
    this.get_data()
    this.get_lang()

  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}

  ngAfterViewInit() {
   
    // setTimeout(()=>{    this.animateCount();},2000)
  }

  get_data(){
    let program_data = firebase.database().ref('template').child('home-blocks')
      program_data.once('value',snapshot=>{
        this.data_numbers=snapshot.val()
      })

  }
  read(){
    let ref = firebase.database().ref('main_data').child('brands')
    ref.once('value',snapshot=>{
      if(snapshot.val()){
        this.main_s=snapshot.val()

      }
    })
  }
  animateCount() {
    let _this = this;

    let single = this.oneItem.nativeElement.innerHTML;

    this.counterFunc(single, this.oneItem, 7000);

    this.count.forEach(item => {
      _this.counterFunc(item.nativeElement.innerHTML, item, 2000);
    });
  }

  counterFunc(end: number, element: any, duration: number) {
    let range, current: number, step, timer;

    range = end - 0;
    current = 0;
    step = Math.abs(Math.floor(duration / range));

    timer = setInterval(() => {
      current += 1;
      element.nativeElement.textContent = current;
      if (current == end) {
        clearInterval(timer);
      }
    }, step);
  }
}
