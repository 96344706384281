import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { Router } from '@angular/router';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import  firebase from 'firebase';

import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-performance-list',
  templateUrl: './performance-list.component.html',
  styleUrls: ['./performance-list.component.css']
})
export class PerformanceListComponent implements OnInit {

  canceled="#f5365c"
  in_progress="#5e72e4"
  waiting="#ffea31"
  finished="#2dce89"
  user_info={a_id:null,uid:'',email:null,emailverified:'',f_name:'',l_name:'',date_birth:'',phone:null,company_name:'',skype_name:'',chiptuning_tool:'',user_name:'',address:'',city:'',country:'',postal_code:'',about:'',img_url:'',a1:'',a2:'',a3:0}

  customers=[]
  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['#','name_en' ,'name_ar','project','Classification','Narrator','date','uid' ,'btn']
 Ref_data = firebase.database().ref('story')
 section_list:any=[]
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('idtable') idtable: TemplateRef<any>;
  elementwindow=[]
  closeResult: string;
 plan={date:null,pl_id:null,name:null, destoryion:null}
  constructor(private router: Router,private modalService: NgbModal,private toastr: ToastrService) {
    this.get_project()
    this.get_stories()

   }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
  
    // throw new Error('Method not implemented.');
    this.dataSource.paginator = this.paginator;
     
     this.dataSource.sort = this.sort;
    
   }
 
   open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


   applyFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;
     this.dataSource.filter = filterValue.trim().toLowerCase();
 
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }
   do_filter() {
    const filterValue = this.filter
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(filterValue)

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  add_new(){
    this.router.navigate(['admin/stories/add'])
  }
stories_list=[]
get_stories(){
  this.stories_list=[]
  let i =1
this.Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleString()
// this.Ref_data.child(snapshot.key).update({ project: "timeless-tales" });
// console.log(y.country)
// if(y.img_list){
//   console.log(y.name_ar)
// }
y.key = snapshot.key
this.stories_list.push(y)
  })
}).then(()=>{
  this.stories_list.reverse()
  for(let index of this.stories_list){
    index.i=i
i=i+1
  }
  // this.plan_list.sort(comparenameup)
  this.dataSource = new MatTableDataSource(this.stories_list)
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
})
}


data_check:boolean=false
add_new_init(){

  let id = "classic_new_plan_0"
  let go = document.getElementById(id)
  go.click()
  
}

edit(event){
  VariablesService.story=null
VariablesService.story=event
this.router.navigate(['/admin/stories/edit'])
}

story_delete_data
  init_delete(event){
    let id = "delete_story_0"
    let go = document.getElementById(id)
    go.click()
    this.story_delete_data=event
  
  }

  go_delete(){
    // let ref = firebase.database().ref('acc').child('plans/'+this.story_delete_data.key)
    this.Ref_data.child(this.story_delete_data.key).remove().then(()=>{
     
    this.toastr.success('The story removed successfully')
    
    }).then(()=>{
      let id = "close_window"
    let go = document.getElementById(id)
    go.click()
      this.get_stories()
      this.story_delete_data=null
    })
  }

  get_project(){
    let ref = firebase.database().ref('main_data').child('project')
    ref.once('value',snapshot=>{
      if(snapshot.val()){
        this.section_list=snapshot.val()

      }
    })

  
 }
 return_project(event:number){

  for(let index of this.section_list){

    if(event == index.id){
      return index.name_en
    }
  }
 }
}


export interface PeriodicElement {
  cid:number,vehicle_type:string,Make:string,Model:string,date:number,Engine:string,ECU:string,Credits:number, }
 export class ButtonOverviewExample {}
 const ELEMENT_DATA: PeriodicElement[] = [
  //  {    cid:1,vehicle_type:'car',Make:'bmw',Model:'m3',date:343432423,Engine:'ppr433e',ECU:'siemens',Credits:10},
 
];


function comparenameup(a, b) {
  const genreA = a.date;
  const genreB = b.date;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison* -1;
}