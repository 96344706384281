import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';
import { PeriodicElement } from '../../subscibers-list/subscibers-list.component';

@Component({
  selector: 'app-artist-edit',
  templateUrl: './artist-edit.component.html',
  styleUrls: ['./artist-edit.component.css']
})
export class ArtistEditComponent implements OnInit {

  close_window(){
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }

  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  image_title
  data_check:boolean=false
  time_now
  uploading_status:boolean=false
  upload_product:boolean=false
  uploadProgress0
  uploadProgress_0
  idColumn = 'key';
  image_data={date:0,img_url:null,img_name:null,img_title:null}
  pdf_data={date:0,pdf_url:null,pdf_name:null,pdf_title:null}
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
@ViewChild('idtable') idtable: TemplateRef<any>;
elementwindow=[]
main_s_data={title_en:null,items:0,slug:null,name:null}
main_s_data_2={title_en:null, items:0,slug:null,name:null}

  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['name' ,'uid' ,'btn']
 Ref_data = firebase.database().ref('photoslibrary')

 photoslibrary_list=[]


 section_list:any

  artist_data={date:0,name_en:null,name_ar:null,active:false,order:1,
    subtitle_en:null,subtitle_ar:null, in_subtitle_en:null,in_subtitle_ar:null,
  key:null,
    body_en:null,body_ar:null,
    program:null,date_artist_en:null,date_artist_ar:null,
    vid_list:[],sound_list:[],
    img_url:null,img_name:null,img_list:[],p_id:null,
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.artist) {
    this.router.navigate(['/admin/artists/list'])

   }else{
    this.set_data()
    this.read_kind_list()
   }
  }

  set_data(){
    this.artist_data=VariablesService.artist
    // this.url0=this.artist_data.img_url
    if( !this.artist_data.sound_list){
      this.artist_data.sound_list=[]
    }
    if( !this.artist_data.vid_list){
      this.artist_data.vid_list=[]
    }
    for(let index of this.artist_data.img_list){
   
      let data1={img_title:null,img_url:null,list_id:null}
      data1.img_title = this.artist_data.p_id
      data1.img_url = index
      
      
      let x1 =(Math.floor(Math.random() * 9) + 1)
  let x2 =(Math.floor(Math.random() * 9) + 1)
  let x3 =(Math.floor(Math.random() * 9) + 1)
   data1.list_id= x1.toString()+x2.toString()+x3.toString()
      

      this.photoslibrary_list.push(data1)
    }
  
  this.dataSource = new MatTableDataSource(this.photoslibrary_list)
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  }
  init_upload(){
    if(this.artist_data.name_en && this.artist_data.name_ar 
      && this.artist_data.subtitle_en &&  this.artist_data.subtitle_ar
      // && this.artist_data.in_subtitle_en  && this.artist_data.in_subtitle_ar
      &&  this.photoslibrary_list.length>0
      && this.artist_data.body_en &&  this.artist_data.body_ar
      && this.artist_data.img_url){
        this.artist_data.img_list=[]
    for(let img of this.photoslibrary_list){
      this.artist_data.img_list.push(img.img_url)
    }
       
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
 
  upload(){
    this.time_now = Date.now()
    
    this.artist_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('artist'); //new
    Ref_data.child(this.artist_data.key).set(this.artist_data).then(()=>{
      this.toastr.success('Upload finished, The artist added artistfully. ')
  
    this.router.navigate(['/admin/artists/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_artist_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

  
  onSelectFile00(event) {
    var imageFile=null
    this.image_name=null
    this.spinner=true
    this.url00=null
    this.url0=null
     imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      this.image_name= Date.now()+imageFile.name
      this.image_data.img_name=Date.now()+imageFile.name
    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url00=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url0 = compressedFile.target.result;
         // console.log('url0',this.url0)

this.images0=true
  this.spinner=false

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  do_filter() {
   const filterValue = this.filter
   this.dataSource.filter = filterValue.trim().toLowerCase();
   console.log(filterValue)

   if (this.dataSource.paginator) {
     this.dataSource.paginator.firstPage();
   }
 }
 
 new_photo_init(){
  this.image_data.img_title = this.artist_data.p_id
let id = "add_photo_11"
let go = document.getElementById(id)
go.click()

}


upload_photo(){
this.image_data.img_title = this.artist_data.p_id
if(this.images0 && this.image_data.img_title ){


this.uploading_status=true
this.time_now = Date.now()
let storageRef = firebase.storage().ref('photoslibrary');
var uploadTask = storageRef.child( this.image_name).put(this.url00)



uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
snapshot=> {
// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
this.uploadProgress0 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
this.uploadProgress_0= Math.floor(this.uploadProgress0)
switch (snapshot.state) {
case firebase.storage.TaskState.PAUSED: // or 'paused'
 this.toastr.warning('Upload is paused');
  break;
case firebase.storage.TaskState.RUNNING: // or 'running'
this.toastr.info('Upload is running');
  break;
}
}, (error=> {

// A full list of error codes is available at
// https://firebase.google.com/docs/storage/web/handle-errors
switch (error.message) {
case 'storage/unauthorized':
// User doesn't have permission to access the object
this.toastr.error("User doesn't have permission to access the object");
break;

case 'storage/canceled':
// User canceled the upload
this.toastr.error("User canceled the upload");

break;



case 'storage/unknown':
// Unknown error occurred, inspect error.serverResponse
this.toastr.error("Unknown error occurred, inspect error.serverResponse");

break;
}
}), (()=> {
// Upload completed successfully, now we can get the download URL
uploadTask.snapshot.ref.getDownloadURL().then((downloadURL=> {

this.image_data.img_url=downloadURL
this.image_data.date=this.time_now



///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
let Ref_data = firebase.database().ref('photoslibrary'); //new
Ref_data.push(this.image_data).then(()=>{
  console.log(Ref_data.get())
  
  this.toastr.success('Upload finished, The photo added successfullysss. ')

  let x1 =(Math.floor(Math.random() * 9) + 1)
  let x2 =(Math.floor(Math.random() * 9) + 1)
  let x3 =(Math.floor(Math.random() * 9) + 1)
  let list_id= x1.toString()+x2.toString()+x3.toString()
    this.image_data['list_id']=list_id
   this.photoslibrary_list.push(this.image_data)

  this.dataSource = new MatTableDataSource(this.photoslibrary_list)
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  this.image_data={date:0,img_url:null,img_name:null,img_title:null}
  this.url00=null
  this.url0=null
  this.uploading_status=false
  let id = "close_window"
  let go = document.getElementById(id)
  go.click()
})

// ).catch(error=>{
//   console.log(error.message)
// })

}));
}));
}else{
this.toastr.error('You should input Photo and title')
this.data_check=true

}
}


project_delete_data
init_delete(event){
  let id = "delete_img_list_0"
  let go = document.getElementById(id)
  go.click()
  this.project_delete_data=event

}

go_delete(){
  // let ref = firebase.database().ref('acc').child('plans/'+this.project_delete_data.key)
 
     
   
        // this.get_photoslibrary()
      let dsData = this.dataSource.data;

      const itemIndex = dsData.findIndex(obj => obj['list_id'] === this.project_delete_data['list_id']);
      this.dataSource.data.splice(itemIndex, 1);
      this.dataSource.paginator = this.paginator;
      this.project_delete_data=null

        this.toastr.success(' Deleted' )
   
        let id = "close_window"
        let go = document.getElementById(id)
        go.click()
 
}
read_kind_list(){
  let ref = firebase.database().ref('main_data').child('artist_kind')
  ref.once('value',snapshot=>{
    if(snapshot.val()){
      this.section_list=snapshot.val()

    }
  })
}

add_vid_list(){
   
  this.artist_data.vid_list.push(this.main_s_data)
 
  this.main_s_data={title_en:null, items:0,slug:null,name:null}
  
}
remove_vid_list(element){
  let dsData = this.artist_data.vid_list;
  const itemIndex = dsData.findIndex(obj => obj['name'] === element.name);
  this.artist_data.vid_list.splice(itemIndex, 1);
  let id = "close_window"
  let go = document.getElementById(id)
  go.click()
}
delete_data
init_delete1(event){
  this.delete_data=null
  let id = "delete_plane_window"
  let go = document.getElementById(id)
  go.click()
  this.delete_data=event

}
init_add_vid(){
  let id = "add_vid_performance_0"
  let go = document.getElementById(id)
  go.click()

}

add_sound_list(){
   
  this.artist_data.sound_list.push(this.main_s_data_2)
  console.log(this.artist_data.sound_list)
  this.main_s_data_2={title_en:null, items:0,slug:null,name:null}
  
}
remove_sound_list(element){
  let dsData = this.artist_data.sound_list;
  const itemIndex = dsData.findIndex(obj => obj['name'] === element.name);
  this.artist_data.sound_list.splice(itemIndex, 1);
  let id = "close_window"
  let go = document.getElementById(id)
  go.click()
}

init_delete2(event){
  this.delete_data=null
  let id = "delete_plane_window_sound"
  let go = document.getElementById(id)
  go.click()
  this.delete_data=event

}
init_add_sound(){
  let id = "add_sound_performance_0"
  let go = document.getElementById(id)
  go.click()

}
}
const ELEMENT_DATA: PeriodicElement[] = [
  //  {    cid:1,vehicle_type:'car',Make:'bmw',Model:'m3',date:343432423,Engine:'ppr433e',ECU:'siemens',Credits:10},
 
];