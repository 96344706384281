import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resources-add',
  templateUrl: './resources-add.component.html',
  styleUrls: ['./resources-add.component.css']
})
export class ResourcesAddComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  resource_data={date:0,name_en:null,name_ar:null,program:null,active:false,order:1,
    subtitle_en:null,subtitle_ar:null,
    date_s:0,writer:null,
    body_en:null,body_ar:null,
    body_kind_en:true,body_kind_ar:true,
    pdf_en:null,pdf_ar:null,
    img_url:null,img_name:null, 
  
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.get_section()
    this.read_writer()
  }

  init_upload(){
    if(this.resource_data.name_en  && this.resource_data.name_ar  && this.resource_data.program
      && this.resource_data.img_url &&  this.resource_data.writer){
        this.upload()
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }


  upload(){
    this.time_now = Date.now()
    
    this.resource_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('resource'); //new
    Ref_data.push(this.resource_data).then(()=>{
      this.toastr.success('Upload finished, The resource added successfully. ')
  
    this.router.navigate(['/admin/resources/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


  section_list=[]
  get_section(){
    this.section_list=['article','research']
  //   this.spinner=true
  //   firebase.database().ref('program').once('value',snapshots=>{
  //   snapshots.forEach(snapshot=>{
  // let y = snapshot.val()
  // var date1 = new Date(y.date);
  // y.date=  date1.toLocaleString()
  
  // y.key = snapshot.key
  // this.section_list.push(y)
  //   })
  // })
  }

  init_add_pdf(){
    let id = "add_pdf_performance_0"
    let go = document.getElementById(id)
    go.click()
  
  }
  init_add_writer(){
    let id = "add_writer_performance_0"
    let go = document.getElementById(id)
    go.click()
  
  }
  writer_list:any=[]
  read_writer(){
    let ref = firebase.database().ref('main_data').child('writer_s')
    ref.on('value',snapshot=>{
      if(snapshot.val()){
        this.writer_list=snapshot.val()
  
      }
    })
  }
}
