import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-values',
  templateUrl: './admin-values.component.html',
  styleUrls: ['./admin-values.component.css']
})
export class AdminValuesComponent implements OnInit {
  data={en_description:null,tr_description:null,ar_description:null}
    ref=firebase.database().ref("about-sycac").child("values")
  
    constructor(private toastr: ToastrService) {
      this.read()
     }
  
    ngOnInit(): void {
    
    }
    read(){
      this.ref.once('value',snapshot=>{
        if(snapshot.exists()){
          this.data=snapshot.val()
        }
   
      })
    }
    save(){
  
      this.ref.set(this.data).catch(error=>{
   
        this.toastr.success(error.message)
      }).then(()=>{
        this.toastr.success('Data update successfully')
      })
    }
  }
  