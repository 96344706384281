import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { Router } from '@angular/router';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import  firebase from 'firebase';
import { VariablesService } from 'src/app/services/variables.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-workplace-list',
  templateUrl: './admin-workplace-list.component.html',
  styleUrls: ['./admin-workplace-list.component.css']
})
export class AdminWorkplaceListComponent implements AfterViewInit {

  canceled="#f5365c"
  in_progress="#5e72e4"
  waiting="#ffea31"
  finished="#2dce89"
  user_info={a_id:null,uid:'',email:null,emailverified:'',f_name:'',l_name:'',date_birth:'',phone:null,company_name:'',skype_name:'',chiptuning_tool:'',user_name:'',address:'',city:'',country:'',postal_code:'',about:'',img_url:'',a1:'',a2:'',a3:0}

  customers=[]
  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['title' ,'lat' ,'lng' ,'accuracy' , 'description','date','btn']
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('idtable') idtable: TemplateRef<any>;
  elementwindow=[]
  closeResult: string;
 
  constructor(private router: Router,private modalService: NgbModal,private toastr: ToastrService) {
    this.get_places()
 
   }

  ngAfterViewInit(): void {
  
    // throw new Error('Method not implemented.');
    this.dataSource.paginator = this.paginator;
     
     this.dataSource.sort = this.sort;
    
   }
 
   open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


   applyFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;
     this.dataSource.filter = filterValue.trim().toLowerCase();
 
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }
   do_filter() {
    const filterValue = this.filter
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(filterValue)

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


edit(event){
  VariablesService.place=event
  this.router.navigate(['admin/workplaces/edit'])
}


plane_delete_data
  init_delete(event){
    let id = "delete_employee_0"
    let go = document.getElementById(id)
    go.click()
    this.plane_delete_data=event
  
  }

  go_delete(){
    let ref = firebase.database().ref('/places/'+this.plane_delete_data.key)
    ref.remove().then(()=>{
     
    this.toastr.success('The place removed successfully')
    
    }).then(()=>{
      let id = "close_window"
    let go = document.getElementById(id)
    go.click()
      this.get_places()
      this.plane_delete_data=null
    })
  }

    
place_list=[]
get_places(){
  this.place_list=[]
let  ref = firebase.database().ref('places')
ref.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.Date=  date1.toLocaleString()

y.key = snapshot.key
this.place_list.push(y)
  })
}).then(()=>{
  this.place_list.reverse()
  this.dataSource = new MatTableDataSource(this.place_list)
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
})
}
}


export interface PeriodicElement {
  cid:number,vehicle_type:string,Make:string,Model:string,date:number,Engine:string,ECU:string,Credits:number, }
 export class ButtonOverviewExample {}
 const ELEMENT_DATA: PeriodicElement[] = [
  //  {    cid:1,vehicle_type:'car',Make:'bmw',Model:'m3',date:343432423,Engine:'ppr433e',ECU:'siemens',Credits:10},
 
];


function comparenameup(a, b) {
  const genreA = a.date;
  const genreB = b.date;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison* -1;
}