import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-contact-us',
  templateUrl: './admin-contact-us.component.html',
  styleUrls: ['./admin-contact-us.component.css']
})
export class AdminContactUsComponent implements OnInit {

  constructor(private toastr: ToastrService,) { }
contact_list={twitter:null,map:null,'whatsapp':null,'facebook':null,instagram:null,youtube:null,skype:null,phone1:null,phone2:null,phone3:null,
phone4:null,phone1_title:null,phone2_title:null,phone3_title:null,phone4_title:null,email:null,location:null,location1:null,soundcloud:null}
  ngOnInit(): void {
 this.read()
  }
  read(){
    let ref = firebase.database().ref('contact-us')
   ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.contact_list=snapshot.val()
      }
 
    })
  }

  save(){
    let ref = firebase.database().ref('contact-us')
    ref.set(this.contact_list).then(()=>{
      this.toastr.success('The data added successfully')
    })
  }
}
