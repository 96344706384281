import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-success-stories-edit',
  templateUrl: './admin-success-stories-edit.component.html',
  styleUrls: ['./admin-success-stories-edit.component.css']
})
export class AdminSuccessStoriesEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  success_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    body_en:null,body_tr:null,body_ar:null,
    img_url:null,img_name:null,key:null}
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.success) {
    this.router.navigate(['/admin/success-stories/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.success_data=VariablesService.success
    this.url0=this.success_data.img_url
  }
  init_upload(){
    if(this.success_data.name_en && this.success_data.name_tr && this.success_data.name_ar 
      && this.success_data.description_en && this.success_data.description_tr && this.success_data.description_ar
      &&  this.success_data.body_en && this.success_data.body_tr && this.success_data.body_ar
       ){
        if(this.images0){
          this.upload()
        }else{
          this.upload_no_img()
        }
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
  onSelectFile00(event) {
    this.image_name=null
    this.spinner=true
    var imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      this.image_name=imageFile.name
      this.success_data.img_name=imageFile.name
    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url00=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url0 = compressedFile.target.result;
         // console.log('url0',this.url0)

this.images0=true
  this.spinner=false

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }
 
  upload(){
    
    this.time_now = Date.now()
    let storageRef = firebase.storage().ref('success');
    var uploadTask = storageRef.child( this.image_name).put(this.url00)

    let id = "uploading_window"
    let go = document.getElementById(id)
    go.click()

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    snapshot=> {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    this.uploadProgress0 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    this.uploadProgress_0= Math.floor(this.uploadProgress0)
    switch (snapshot.state) {
    case firebase.storage.TaskState.PAUSED: // or 'paused'
     this.toastr.warning('Upload is paused');
      break;
    case firebase.storage.TaskState.RUNNING: // or 'running'
    this.toastr.info('Upload is running');
      break;
    }
    }, (error=> {
    
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.message) {
    case 'storage/unauthorized':
    // User doesn't have permission to access the object
    this.toastr.error("User doesn't have permission to access the object");
    break;
    
    case 'storage/canceled':
    // User canceled the upload
    this.toastr.error("User canceled the upload");

    break;
    
    
    
    case 'storage/unknown':
    // Unknown error occurred, inspect error.serverResponse
    this.toastr.error("Unknown error occurred, inspect error.serverResponse");

    break;
    }
    }), (()=> {
    // Upload completed successfully, now we can get the download URL
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL=> {
    
    this.success_data.img_url=downloadURL
    this.success_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('success'); //new
    Ref_data.child(this.success_data.key).set(this.success_data).then(()=>{
      this.toastr.success('Upload finished, The success added successfully. ')
      let id = "close_window"
      let go = document.getElementById(id)
      go.click()
    this.router.navigate(['/admin/success-stories/list'])
   
    })
    
    // ).catch(error=>{
    //   console.log(error.message)
    // })
    
    }));
    }));
  }
  upload_no_img(){
    
    this.time_now = Date.now()

    this.success_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('success'); 
    Ref_data.child(this.success_data.key).set(this.success_data).then(()=>{
      this.toastr.success('Upload finished, The success added successfully. ')
    
    this.router.navigate(['/admin/success-stories/list'])
   
    })
    
    // ).catch(error=>{
    //   console.log(error.message)
    // })

  }



  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

}
