import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-programs-home',
  templateUrl: './programs-home.component.html',
  styleUrls: ['./programs-home.component.css']
})
export class ProgramsHomeComponent implements OnInit {
  cardslist
  Ref_data = firebase.database().ref('programs')
  constructor( private router: Router) { 
    this.cardslist = Array(3).fill(0).map((x,i)=>i+1);

  }

  ngOnInit(): void {
    this.get_lang()
    this.get_programs()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}
programs_list=[]
get_programs(){
  this.programs_list=[]

this.Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleDateString()

y.key = snapshot.key
this.programs_list.push(y)
  })
}).then(()=>{
  this.programs_list.reverse()
})
}
go_program(event){
  VariablesService.program=event
  this.router.navigate(['/program/'+event.acronym])
}
}
