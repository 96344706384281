import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-section-edit',
  templateUrl: './section-edit.component.html',
  styleUrls: ['./section-edit.component.css']
})
export class SectionEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  section_data={date:0,name_en:null,name_ar:null,subtitle_en:null,subtitle_ar:null,active:false,order:1,
    body_en:null,body_ar:null,
    img_url:null,img_name:null,key:null,
    style:{ bg:'#ffffff',color:'#000000',logo:true}}
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.section) {
    this.router.navigate(['/admin/section/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.section_data=VariablesService.section
    this.url0=this.section_data.img_url
  }
  init_upload(){
    if(this.section_data.name_en && this.section_data.name_ar 
      && this.section_data.subtitle_en &&  this.section_data.subtitle_ar
      &&  this.section_data.body_en &&  this.section_data.body_ar
      && this.section_data.img_url){
      
       
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
 
  upload(){
    this.time_now = Date.now()
    
    this.section_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('section'); //new
    Ref_data.child(this.section_data.key).set(this.section_data).then(()=>{
      this.toastr.success('Upload finished, The section added sectionfully. ')
  
    this.router.navigate(['/admin/section/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

}
