import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.css']
})
export class MemberEditComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string 
 
    image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  member_data={date:0,name_en:null,name_ar:null,section:null,active:false,order:1,
    position_en:null,position_ar:null,
    body_en:null,body_ar:null,
    img_url:null,img_name:null,key:null,
    style:1
  }
  style_list=[1,2,3]
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.member) {
    this.router.navigate(['/admin/members/list'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.get_section()
    this.member_data=VariablesService.member
    
  }
  init_upload(){
    if(this.member_data.name_en && this.member_data.name_ar  
   
      &&  this.member_data.body_en &&  this.member_data.body_ar 
      && this.member_data.img_url
       ){
     
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }

  upload(){
    this.time_now = Date.now()
    
    this.member_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('member'); //new
    Ref_data.child(this.member_data.key).set(this.member_data).then(()=>{
      this.toastr.success('Upload finished, The member added sectionfully. ')
  
    this.router.navigate(['/admin/members/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  section_list=[]
get_section(){
  this.section_list=[]
  this.spinner=true
  firebase.database().ref('section').once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleString()

y.key = snapshot.key
this.section_list.push(y)
  })
})
}
}
