import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-sycac',
  templateUrl: './about-sycac.component.html',
  styleUrls: ['./about-sycac.component.css']
})
export class AboutSycacComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
