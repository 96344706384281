import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-success-story',
  templateUrl: './success-story.component.html',
  styleUrls: ['./success-story.component.css']
})
export class SuccessStoryComponent implements OnInit {
  id
  show:boolean=false
  success_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    body_en:null,body_tr:null,body_ar:null,
    img_url:null,img_name:null}
  constructor(private router: Router,protected sanitizer: DomSanitizer,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params['id']
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.get_success()
    this.get_lang()
  
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.success

}
  get_success(){
    if(this.id){
  // let key = VariableService.ecu_service.key
  let ref = firebase.database().ref('success').orderByChild('name_en').equalTo(this.id).limitToLast(1)
      ref.once('value',snapshots=>{

        if(snapshots.exists()){
          snapshots.forEach(snapshot=>{
            this.success_data=snapshot.val()
            this.success_data.body_en= this.transform(this.success_data.body_en)
            this.success_data.body_tr= this.transform(this.success_data.body_tr)
            this.success_data.body_ar= this.transform(this.success_data.body_ar)
          
          
          })
        
        }else{
          this.router.navigate(['/success-stories'])
            }
      
      }).then(()=>{
        this.get_other_success()
        this.show=true
      })
    }
  }
success_list=[]
get_other_success(){
  this.success_list=[]
let  Ref_data = firebase.database().ref('success').orderByKey().limitToLast(4)

Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
if(y.name_en != this.success_data.name_en){
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleDateString()
  
  y.key = snapshot.key
  this.success_list.push(y)
}

  })
})
}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  read_more(event){
    // VariablesService.project=event
     
    this.router.navigate(['success-stories/'+event.name_en])

  }
 
}
