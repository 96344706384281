import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  langpage
  defaultValue
  public static language
  constructor() { }

  getUsersLocale(){
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
     // return defaultValue;
      //console.log(this.defaultValue)
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : this.defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
   // return lang;
    //alert(lang.substring(0,2 ))
    
    this.langpage=lang.substring(0,2 )
    this.get_lang()
  }

  get_lang(){
    if(localStorage.getItem('language')!=null){
    //console.log(localStorage.getItem('language'))
    switch (localStorage.getItem('language')) {
      case 'en':
        LanguagesService.language=this.en
       localStorage.setItem("dir","ltr")
       localStorage.setItem('language','en')
        break;
        case 'ar':
          LanguagesService.language=this.ar
          localStorage.setItem("dir","rtl")
          localStorage.setItem('language','ar')
          break;
          case 'tr':
            LanguagesService.language=this.tr
            localStorage.setItem("dir","ltr")
            localStorage.setItem('language','tr')
            break;
      default:  LanguagesService.language=this.ar
      localStorage.setItem("dir","ltr")
      localStorage.setItem('language','en')
        break;
    }
  }else{
    switch (this.langpage) {
      case 'en':
        case 'en':
        LanguagesService.language=this.en
       localStorage.setItem("dir","ltr")
       localStorage.setItem('language','en')
        break;
        case 'ar':
          LanguagesService.language=this.ar
          localStorage.setItem("dir","rtl")
          localStorage.setItem('language','ar')
          break;
          case 'tr':
            LanguagesService.language=this.tr
            localStorage.setItem("dir","ltr")
            localStorage.setItem('language','tr')
            break;
      default:  LanguagesService.language=this.ar
      localStorage.setItem("dir","ltr")
      localStorage.setItem('language','en')
        break;
      }
    
    }
  }

  en={
    navbar:{
      HOME:"HOME",
      ABOUT_SYCAC:'WHO WE ARE',
      Where_We_Work:'WHERE WE WORK',
      Mission:'SYCAC Mission',
      Vision:'SYCAC Vision',
      Values:'SYCAC Values',
      Our_Programs:'WHAT WE DO',
      Projects:'Projects',
      Other:'OTHER',
      News_vents:' News & Events',
      Success_Stories:'Success Stories',
      Videos_Library:' Videos Library',
      Photos_Library:'Photos Library',
      SYCAC_Profile:'SYCAC Profile',
      Join_us:'Join Us',
       As_Staff:'As Staff',
       As_Volunteer:'As Volunteer',
       Contact_us:'Contact Us',

       All_Rights_Reserved:'All Rights Reserved',
       SYCAC:'SYCAC',
       subscribe_SYCAC_Newsletter:' subscribe in SYCAC Newsletter ',
       Email:'Email',
       full_Name:'full Name',
       Address:"Address (optinal) ",
       Phone_number:'Phone number (optinal)',
       Subscribe:'Subscribe',
       error_mail:'Email must be entered in a correct format',
       input_data:'You must input the data first',
       newsletter_subscribed:'The newsletter has been subscribed',
       email_existed:'This email has already been subscribed to the newsletter',
    },
    footer:{},
    home_page:{
      read_more:"Read more",
      more_news_events:"More news and events",
      SYCAC_activity:"key Achievements  ",
      SYCAC_programs:"SYCAC Programs",
      SYCAC_partners:"SYCAC Partners",
      Media_library:"Media Library",
      Photos_Library:'Photos Library',
      Videos_Library:"Videos Library",
      SYCAC_Profile:"SYCAC Profile",
      SYCAC_Activity_Point:'key Achievements ',
    },
    program:{
      projects:'Projects',

    },
    project:{
      Project_Analytics:'Project Analytics',
      Project_progress:'Project progress',
      Duration_implementation:'Duration of implementation',
      Target_project:'Target of project',
      Other_Projects:'Other Projects from ',
      Program:'Program'

    },
    about_sycac:{
      Where_We_Work:'Where We Work',
      SYCAC_Activity_Point:'key Achievements',
      Mission:'SYCAC Mission',
       Vision:'SYCAC Vision',
        Values:'SYCAC Values'
    },
    news:{
      other_news:'More News & Events',
      News_Events:" News & Events",
      read_more:"Read more",
    },
    success:{
      Success_Stories_Testimonials:" Success Stories ",
      read_more:"Read more",
      other_success:'More Success Stories ',
    },
    other:{
      more_videos:"See more",
      Videos_Library:"Videos Library",
      Photos_Library:'Photos Library',
      show_more:" More"
    },
    join_us:{
      Available_Positions:'Available Positions ',
      Volunteer_with_SYCAC:'Volunteer with SYCAC',
      read_more:"Read more",
      other_jobs:'More Available Positions ',
      Contact_Team:'Contact with SYCAC'


    }
  }
  tr={
       navbar:{
      HOME:"Ana Sayfa",
      ABOUT_SYCAC:'BIZ KIMIZ',
      Where_We_Work:'Nerede Çalışıyoruz',
      Mission:'SYCAC Misyon',
      Vision:'SYCAC Vizyon',
      Values:'SYCAC Değerler',
      Our_Programs:'Ne Yapıyoruz',
      Projects:'Projeler',
      Other:'Diğer',
      News_vents:'Haberler ve Olaylar',
      Success_Stories:'Başarı Hikayeleri',
      Videos_Library:' Video albüm',
      Photos_Library:'Fotoğraf albüm',
      SYCAC_Profile:'SYCAC Profili',
      Join_us:'Bize Katılın',
       As_Staff:'Personel Olarak',
       As_Volunteer:'Gönüllü Olarak',
       Contact_us:'Bize ulaşın',
       
       All_Rights_Reserved:'Tüm Hakları Saklıdır',
       SYCAC:'SYCAC',
       subscribe_SYCAC_Newsletter:' SYCAC Bültenine abone olun ',
       Email:'E-posta',
       full_Name:'tam Ad',
       Address:"Adres (isteğe bağlı) ",
       Phone_number:'Telefon numarası (isteğe bağlı)',
       Subscribe:'Abone Ol',
       error_mail:'E-posta doğru biçimde girilmelidir',
       input_data:'Önce verileri girmelisiniz',
       newsletter_subscribed:'Bülten abone oldu',
       email_existed:'Bu e-posta zaten haber bültenine abone oldu',
    },
    footer:{},
    home_page:{
      read_more:"fazla oku",
      more_news_events:"Daha fazla haber ve etkinlik",
      SYCAC_activity:"Başarılar",
      SYCAC_programs:"SYCAC Programlar",
      SYCAC_partners:"SYCAC Ortaklar",
      Media_library:"Medya kütüphanesi",
      Videos_Library:' Video albüm',
      Photos_Library:'Fotoğraf albüm',
      SYCAC_Profile:'SYCAC Profili',
      SYCAC_Activity_Point:'Başarılar',


    },
    program:{
      projects:'Projeler',
      
    },
    project:{
      Project_Analytics:'Proje Analitiği',
      Project_progress:'Proje ilerlemesi',
      Duration_implementation:'Uygulama süresi',
      Target_project:'Projenin hedefi',
      Other_Projects:'Diğer Projeler',
      Program:'Program'

    },
    about_sycac:{
      Where_We_Work:'Nerede Çalışıyoruz',
      SYCAC_Activity_Point:'Başarılar',
      Mission:'SYCAC Misyon',
      Vision:'SYCAC Vizyon',
      Values:'SYCAC Değerler',
    },
    news:{
      other_news:'diğer Haberler & Olaylar',
      News_Events:"Haberler & Olaylar",
      read_more:"fazla oku",
    },
    success:{
      Success_Stories_Testimonials:" Başarı Öyküleri ",
      read_more:"fazla oku",
      other_success:'Daha Fazla Başarı Hikayesi ',
    },
    other:{
      more_videos:"Daha fazla",
      Videos_Library:' Video albüm',
      Photos_Library:'Fotoğraf albüm',
      show_more:" Daha fazla"
    },
    join_us:{
      Available_Positions:'Mevcut Pozisyonlar',
      Volunteer_with_SYCAC:'SYCAC ile gönüllü olun',
      read_more:"fazla oku",
      other_jobs:'Daha Mevcut Pozisyonlar ',
      Contact_Team:'SYCAC  iletişim'
    },
    
  }
  ar={
    navbar:{
      HOME:"الرئيسية",
      ABOUT_SYCAC:'من نحن',
      Where_We_Work:'أين نعمل',
      Mission:'أهداف SYCAC ',
      Vision:'رؤية SYCAC ',
      Values:'قيم SYCAC ',
      Our_Programs:'ماذا نعمل',
      Projects:'المشاريع',
      Other:'المزيد',
      News_vents:' الأخبار والأحداث',
      Success_Stories:'قصص نجاح',
      Videos_Library:'مكتبة الفيديو',
      Photos_Library:'مكتبة الصور',
      SYCAC_Profile:'  الملف التعريفي ',
      Join_us:'انضم إلينا',
       As_Staff:'كموظف',
       As_Volunteer:'كمتطوع',
       Contact_us:'الاتصال بنا',
       
       All_Rights_Reserved:'جميع الحقوق محفوظة',
       SYCAC:'SYCAC ',
       subscribe_SYCAC_Newsletter:' اشترك في نشرتنا البريدية',
       Email:'البريد الالكتروني',
       full_Name:'الاسم الكامل',
       Address:" العنوان (اختياري) ",
       Phone_number:'رقم الهاتف (اختياري)',
       Subscribe:'اشتراك',
       error_mail:'يجب إدخال البريد الإلكتروني بالتنسيق الصحيح',
       input_data:'يجب إدخال البيانات أولا',
       newsletter_subscribed:'تم الإشتراك بالنشرة البريدية',
       email_existed:'هذا البريد الإلكتروني  تم الاشتراك به بالفعل بالنشرة البريدية',
  
    },
    footer:{},
    home_page:{
      read_more:"إقرأ المزيد",
      more_news_events:"المزيد من الأخبار والأحداث",
      SYCAC_activity:" الإنجازات ",
      SYCAC_programs:"SYCAC برامج",
      SYCAC_partners:"SYCAC شركاء",
      Media_library:"مكتبة الوسائط",
      Photos_Library:'مكتبة الصور',
      Videos_Library:'مكتبة الفيديو',
      SYCAC_Profile:'  الملف التعريفي ',
      SYCAC_Activity_Point:'الإنجازات ',
    },
    program:{
      projects:' مشاريع برنامج',
      
    },
    project:{
      Project_Analytics:'تحليل المشروع',
      Project_progress:'تقدم المشروع',
      Duration_implementation:'مدة التنفيذ',
      Target_project:'الهدف من المشروع',
      Other_Projects:' مشاريع أخرى من برنامج',
      Program:''

    },
    about_sycac:{
      Where_We_Work:'أين نعمل',
      SYCAC_Activity_Point:'الإنجازات ',
      Mission:'أهداف SYCAC ',
      Vision:'رؤية SYCAC ',
      Values:'قيم SYCAC ',
    },
    news:{
      other_news:'أخبار و أحداث أخرى',
      News_Events:"أخبار و أحداث",
      read_more:"إقرأ المزيد",
    },
    success:{
      Success_Stories_Testimonials:"قصص نجاح ",
      read_more:"إقرأ المزيد",
      other_success:'المزيد من قصص النجاح ',
    },
    other:{
      more_videos:"شاهد المزيد",
      Videos_Library:'مكتبة الفيديو',
      Photos_Library:'مكتبة الصور',
      show_more:" المزيد"

    },
    join_us:{
      Available_Positions:'الوظائف المتاحة ',
      Volunteer_with_SYCAC:'تطوع مع سيكاك',
      read_more:"إقرأ المزيد",
      other_jobs:'المزيد من الوظائف المتاحة ',
      Contact_Team:'تواصل مع SYCAC'
    }
  }
}