import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-about-project',
  templateUrl: './about-project.component.html',
  styleUrls: ['./about-project.component.css']
})
export class AboutProjectComponent implements OnInit {

  close_window(){
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }

  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  image_title
  data_check:boolean=false
  time_now
  uploading_status:boolean=false
  upload_product:boolean=false
  uploadProgress0
  uploadProgress_0
  idColumn = 'key';
  image_data={date:0,img_url:null,img_name:null,img_title:null}
  pdf_data={date:0,pdf_url:null,pdf_name:null,pdf_title:null}
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
@ViewChild('idtable') idtable: TemplateRef<any>;
elementwindow=[]
 
  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['name' ,'uid' ,'btn']
 Ref_data = firebase.database().ref('photoslibrary')

 photoslibrary_list=[]


 section_list_1=['published','unpublished']
 section_list: any[] = [

  { value: 'published', label: 'Available' },
  { value: 'unpublished', label: 'unavailable' },
];
 main_s_data={title_en:null, img_url:null, items:0,slug:null,name:null}


  project_data={
    date:0,name_en:null,name_ar:null,order:1,
    subtitle_en:null,subtitle_ar:null,
    key:null,
    body_sm_en:null,body_sm_ar:null,
    body_en:null,body_ar:null,
    id:null,
    img_url:null,img_name:null,pdf_list:[],
    logo_en:null,logo_ar:null
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!VariablesService.project) {
    this.router.navigate(['/admin/database-block/projects'])

   }else{
    this.set_data()
   }
  }

  set_data(){
    this.read_director()
    this.read_kind()
  

    let Ref_data = firebase.database().ref('project'); //new
    Ref_data.child(VariablesService.project.slug).once('value',snapshot=>{
      if(snapshot.val()){
        this.project_data=snapshot.val()
        if(!this.project_data.pdf_list){
      
            this.project_data.pdf_list=[]
         
        }
      }
    })
    // this.url0=this.project_data.img_url
    // if( this.project_data.pdf_list){
    //   for(let index of this.project_data.pdf_list){
   
    //     let data1={img_title:null,img_url:null,list_id:null}
    //     data1.img_title = this.project_data.id
    //     data1.img_url = index
        
        
    //     let x1 =(Math.floor(Math.random() * 9) + 1)
    // let x2 =(Math.floor(Math.random() * 9) + 1)
    // let x3 =(Math.floor(Math.random() * 9) + 1)
    //  data1.list_id= x1.toString()+x2.toString()+x3.toString()
        
  
    //     this.photoslibrary_list.push(data1)
    //   } 
    // }else{
    //   this.project_data.pdf_list=[]
    // }
  
  

  }
  init_upload(){
    if(this.project_data.name_en && this.project_data.name_ar 
      && this.project_data.subtitle_en &&  this.project_data.subtitle_ar
      && this.project_data.body_sm_en &&  this.project_data.body_sm_ar
      && this.project_data.body_en &&  this.project_data.body_ar
      && this.project_data.img_url){
    
   
       
          this.upload()
        
       
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }
 
  upload(){
    this.time_now = Date.now()
    
    this.project_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('project'); //new
    Ref_data.child(VariablesService.project.id).set(this.project_data).then(()=>{
      this.toastr.success('Upload finished, The project added projectfully. ')
  
    this.router.navigate(['/admin/database-block/projects'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_project_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

  
  onSelectFile00(event) {
    var imageFile=null
    this.image_name=null
    this.spinner=true
    this.url00=null
    this.url0=null
     imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      this.image_name= Date.now()+imageFile.name
      this.image_data.img_name=Date.now()+imageFile.name
    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url00=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url0 = compressedFile.target.result;
         // console.log('url0',this.url0)

this.images0=true
  this.spinner=false

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }


 new_photo_init(){
  this.image_data.img_title = this.project_data.id
let id = "add_photo_11"
let go = document.getElementById(id)
go.click()

}





project_delete_data
init_delete(event){
  let id = "delete_img_list_0"
  let go = document.getElementById(id)
  go.click()
  this.project_delete_data=event

}


init_add_vid(){
  let id = "add_vid_project_0"
  let go = document.getElementById(id)
  go.click()

}

director_list:any=[]
read_director(){
  this.director_list=[]
  let ref = firebase.database().ref('main_data').child('director')
  ref.on('value',snapshot=>{
    if(snapshot.val()){
      this.director_list=snapshot.val()

    }
  })
}

kind_list:any=[]
read_kind(){
  this.kind_list=[]
  let ref = firebase.database().ref('main_data').child('kind')
  ref.on('value',snapshot=>{
    if(snapshot.val()){
      this.kind_list=snapshot.val()

    }
  })
}

add_vid_list(){
    console.log(this.main_s_data)
  this.project_data.pdf_list.push(this.main_s_data)
  this.main_s_data={title_en:null, img_url:null, items:0,slug:null,name:null}
  
}
remove_vid_list(element){
  let dsData = this.project_data.pdf_list;
  const itemIndex = dsData.findIndex(obj => obj['title_en'] === element.title_en);
  this.project_data.pdf_list.splice(itemIndex, 1);
  let id = "close_window"
  let go = document.getElementById(id)
  go.click()
}
delete_data
init_delete1(event){
  this.delete_data=null
  let id = "delete_plane_window"
  let go = document.getElementById(id)
  go.click()
  this.delete_data=event

}

init_add_director(){
  let id = "add_director_project_0"
  let go = document.getElementById(id)
  go.click()

}

init_add_kind(){
  let id = "add_kind_project_0"
  let go = document.getElementById(id)
  go.click()

}
init_add_pdf(){
  let id = "add_pdf_story_0"
  let go = document.getElementById(id)
  go.click()

}

convertToSlug(productName) {
  // Remove non-alphanumeric characters and spaces
  const sanitizedName = productName.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();

  // Replace spaces with hyphens
  const slug = sanitizedName.replace(/\s+/g, '-');

  // Handle edge cases (empty strings or single hyphens)
  if (slug === '-' || slug === '') {
    return 'invalid-slug';
  }

  return slug;
}
}
