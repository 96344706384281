import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';

@Component({
  selector: 'app-where-we-work',
  templateUrl: './where-we-work.component.html',
  styleUrls: ['./where-we-work.component.css']
})
export class WhereWeWorkComponent implements OnInit {
  data={en_description:null,tr_description:null,ar_description:null}
  ref=firebase.database().ref("about-sycac").child("where-we-work")
  constructor(protected sanitizer: DomSanitizer) {
    this.read()
   }

  ngOnInit(): void {
    this.get_lang()
   
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.about_sycac

}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
 
  read(){
    this.ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.data=snapshot.val()
        this.data.en_description= this.transform(this.data.en_description)
        this.data.tr_description= this.transform(this.data.tr_description)
        this.data.ar_description= this.transform(this.data.ar_description)      }
 
    })
  }
}
