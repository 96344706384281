import { Component, OnInit } from '@angular/core';
import imageCompression from 'browser-image-compression';
import  firebase from "firebase/app";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  url11
  url1:string 
  ///

  url22
  url2:string 
  ///

  url33
  url3:string 
  constructor(private toastr: ToastrService) { 
let ref = firebase.database().ref('template/slide-show')
ref.once('value',snapshots=>{
 this.url1= snapshots.val().img_1
 this.url2= snapshots.val().img_2
 this.url3= snapshots.val().img_3
})
    
  }

  ngOnInit(): void {
  }
  onSelectFile11(event) {
    
    var imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    var options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url11=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url1= compressedFile.target.result;
         // console.log('url0',this.url0)
         this.update_photo_1(this.url11)

  

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }
  onSelectFile22(event) {
    
    var imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    var options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url22=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url2 = compressedFile.target.result;
         // console.log('url0',this.url0)
         this.update_photo_2(this.url22)

  

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }
  onSelectFile33(event) {
    
    var imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    var options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url33=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url3 = compressedFile.target.result;
         // console.log('url0',this.url0)
         this.update_photo_3(this.url33)

  

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }
  update_photo_1(url){
 let uploadTask =   firebase.storage().ref('template/slide-show').child('img_1').put(url)
    
 uploadTask.snapshot.ref.getDownloadURL().then(downloadURL=> {
   console.log(downloadURL)
  let ref = firebase.database().ref('template/slide-show')
  ref.update({img_1:downloadURL})

 this.toastr.success('photo no:1 Modified successfully' )
});
   
  }
  update_photo_2(url){
    let uploadTask =   firebase.storage().ref('template/slide-show').child('img_2').put(url)
    
    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL=> {
      console.log(downloadURL)
     let ref = firebase.database().ref('template/slide-show')
     ref.update({img_2:downloadURL})
   
    this.toastr.success('photo no:2 Modified successfully' )
   });
      
  }
  update_photo_3(url){
    let uploadTask =   firebase.storage().ref('template/slide-show').child('img_3').put(url)
    
    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL=> {
      console.log(downloadURL)
     let ref = firebase.database().ref('template/slide-show')
     ref.update({img_3:downloadURL})
   
    this.toastr.success('photo no:3 Modified successfully' )
   });
      
  }
}
