import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import  firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-workplace-edit',
  templateUrl: './admin-workplace-edit.component.html',
  styleUrls: ['./admin-workplace-edit.component.css']
})
export class AdminWorkplaceEditComponent implements OnInit {

  data_check:boolean=false
  ref = firebase.database().ref('places')
  place={lat:null,lng:null,accuracy:null,description_en:null,description_ar:null,description_tr:null,date:null,title_en:null,title_ar:null,title_tr:null}  


   position_sub=[
    ['00','A'],
    ['01','B'],
    ['02','C'],
    ['03','D'],
    ['04','E'],
    ['05','F'],
  
  ]
  constructor(private router: Router,private toastr: ToastrService,private modalService: NgbModal) { 
 if(VariablesService.place){
   this.place=VariablesService.place
 }else{
  this.router.navigate(['admin/workplaces/list'])
 }
   }

  ngOnInit(): void {
  }
  closeResult: string;
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  init_submit(){
    if(this.place.accuracy  && this.place.description_ar && this.place.description_en && this.place.description_tr && this.place.lat && this.place.lng && this.place.title_ar  && this.place.title_ar  && this.place.title_tr )
    {
    
      
            
            this.place.date= Date.now();
            let id = "submit_recharge"
            let go = document.getElementById(id)
            go.click()
      
   
      }
  
    else{
     

  
      this.data_check=true
   this.toastr.warning('You must input all data')
    }
  }
  // get_uid(){
  //   const timenow = Date.now();
  //   var date = new Date(timenow)
  //   var year1 = date.getFullYear()
  //   let year=year1.toString()
  //   year = year.substr(year.length -2)
  //   var month = date.getMonth()+1
  //   let x1 =(Math.floor(Math.random() * 9) + 1)
  //   let x2 =(Math.floor(Math.random() * 9) + 1)
  //   let x3 =(Math.floor(Math.random() * 9) + 1)
  //   let x4 =(Math.floor(Math.random() * 9) + 1)
  //   let x5 =(Math.floor(Math.random() * 9) + 1)
  //   let x6 =(Math.floor(Math.random() * 9) + 1)
  //   let id=year+month.toString()+ x1.toString()+x2.toString()+x3.toString()+x4.toString()+x5.toString()+x6.toString()
   
  
  //     return id
  // }
submit(){
this.ref.child(this.place['key']).set(this.place).then(()=>{
  this.toastr.success('The place added successfully')
  let id = "close_window"
  let go = document.getElementById(id)
  go.click()          
  this.router.navigate(['admin/workplaces/list'])
})
}


}
