import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  cardslist
  Ref_data = firebase.database().ref('partner')

  constructor() {
    this.cardslist = Array(3).fill(0).map((x,i)=>i+1);

   }

  ngOnInit(): void {
    this.get_lang()
    this.get_partner()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}

partner_list=[]
get_partner(){
  this.partner_list=[]

this.Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleDateString()

y.key = snapshot.key
this.partner_list.push(y)
  })
}).then(()=>{
  this.partner_list.reverse()
})
}
}
