import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sponsor-add',
  templateUrl: './sponsor-add.component.html',
  styleUrls: ['./sponsor-add.component.css']
})
export class SponsorAddComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  sponsor_data={date:0,program:null,
    url:null,
   
    
    
    img_url:null,
  
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.get_section()
  }

  init_upload(){
 
    if( this.sponsor_data.program &&
      this.sponsor_data.url
      && this.sponsor_data.img_url ){
        this.upload()
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }


  upload(){
    this.time_now = Date.now()
    
    this.sponsor_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('sponsor'); //new
    Ref_data.push(this.sponsor_data).then(()=>{
      this.toastr.success('Upload finished, The sponsor added successfully. ')
  
    this.router.navigate(['/admin/sponsor/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


  section_list=[]
  get_section(){
    this.section_list=['Sponsor','Organizer']
  //   this.spinner=true
  //   firebase.database().ref('program').once('value',snapshots=>{
  //   snapshots.forEach(snapshot=>{
  // let y = snapshot.val()
  // var date1 = new Date(y.date);
  // y.date=  date1.toLocaleString()
  
  // y.key = snapshot.key
  // this.section_list.push(y)
  //   })
  // })
  }


}
