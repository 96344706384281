import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})
export class ProgramComponent implements OnInit {
  id
  show:boolean=false
  program_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    body_en:null,body_tr:null,body_ar:null,
    img_url:null,img_name:null,acronym :null}
  constructor(private titleService: Title, private metaService: Meta,private router: Router,protected sanitizer: DomSanitizer,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params['id']
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.get_lang()
    this.get_program()
 
  
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.program

}
  get_program(){
    if(this.id){
  // let key = VariableService.ecu_service.key
  let ref = firebase.database().ref('programs').orderByChild('acronym').equalTo(this.id).limitToLast(1)
      ref.once('value',snapshots=>{

        if(snapshots.exists()){
          snapshots.forEach(snapshot=>{
            this.program_data=snapshot.val()
            this.program_data.body_en= this.transform(this.program_data.body_en)
            this.program_data.body_tr= this.transform(this.program_data.body_tr)
            this.program_data.body_ar= this.transform(this.program_data.body_ar)

          })
        
        }else{
          this.router.navigate(['/home'])
            }
      
      }).then(()=>{
        switch (this.langstatus) {
          case 'en':
            this.do_tag_en()
            break;
            case 'tr':
              this.do_tag_tr()
              break;
              case 'ar':
                this.do_tag_ar()
                break;
  
        }
        
        // this.get_news()
        this.show=true
      })
    }
  }
news_list=[]
get_news(){
  this.news_list=[]
let  Ref_data = firebase.database().ref('projects').orderByChild('program').equalTo(this.program_data.acronym)

Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleDateString()

y.key = snapshot.key
this.news_list.push(y)
  })
})
}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
  read_more(event){
    VariablesService.project=event
     
    this.router.navigate(['project/'+event.name_en])

  }

  do_tag_en(){
    
 
     
      let date1 = new Date(Date.now());
      let date =date1.toLocaleDateString()
    
      this.titleService.setTitle(this.program_data.name_en);
      this.metaService.addTags([
        
        { name: 'description', content: this.program_data.description_en},
        { name: 'keywords', content: 'sycac,civil administration center,civil administration,' },
        { name: 'robots', content: 'this.program_data.name_en' },
        { name: 'author', content: 'sycac' },
        { name: 'language', content: 'EN' },
        { name: 'date', content: date, scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' },
        { name : 'og:title' , property :'og:title',content:this.program_data.name_en },
        { name : 'og:description' , property :'og:description',content:this.program_data.description_en},
        { name : 'og:image' , property :'og:image',content:this.program_data.img_url},
        { name : 'twitter:card' ,content:this.program_data.description_en },
        { name : 'twitter:site' ,content:'sycac.org' },
        { name : 'twitter:creator' ,content:'@sycac' },

        { name : 'designer' ,content:'yazan-amin' },
        
       
      ]);
    }
    do_tag_tr(){
    
 
     
      let date1 = new Date(Date.now());
      let date =date1.toLocaleDateString()
    
      this.titleService.setTitle(this.program_data.name_tr);
      this.metaService.addTags([
        
        { name: 'description', content: this.program_data.description_tr},
        { name: 'keywords', content: 'sycac,civil administration center,civil administration,' },
        { name: 'robots', content: 'this.program_data.name_tr' },
        { name: 'author', content: 'sycac' },
        { name: 'language', content: 'TR' },
        { name: 'date', content: date, scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' },
        { name : 'og:title' , property :'og:title',content:this.program_data.name_tr },
        { name : 'og:description' , property :'og:description',content:this.program_data.description_tr},
        { name : 'og:image' , property :'og:image',content:this.program_data.img_url},
        { name : 'twitter:card' ,content:this.program_data.description_tr },
        { name : 'twitter:site' ,content:'sycac.org' },
        { name : 'twitter:creator' ,content:'@sycac' },

        { name : 'designer' ,content:'yazan-amin' },
        
       
      ]);
    }
    do_tag_ar(){
    
 
     
      let date1 = new Date(Date.now());
      let date =date1.toLocaleDateString()
    
      this.titleService.setTitle(this.program_data.name_ar);
      this.metaService.addTags([
        
        { name: 'description', content: this.program_data.description_ar},
        { name: 'keywords', content: 'sycac,بوابة المجتمع المدني,' },
        { name: 'robots', content: 'this.program_data.name_ar' },
        { name: 'author', content: 'sycac' },
        { name: 'language', content: 'EN' },
        { name: 'date', content: date, scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' },
        { name : 'og:title' , property :'og:title',content:this.program_data.name_ar },
        { name : 'og:description' , property :'og:description',content:this.program_data.description_ar},
        { name : 'og:image' , property :'og:image',content:this.program_data.img_url},
        { name : 'twitter:card' ,content:this.program_data.description_ar },
        { name : 'twitter:site' ,content:'sycac.org' },
        { name : 'twitter:creator' ,content:'@sycac' },

        { name : 'designer' ,content:'يزن الأمين' },
        
       
      ]);
    }
}
