import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { Toast, ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editos',
  templateUrl: './editos.component.html',
  styleUrls: ['./editos.component.css']
})
export class EditosComponent implements OnInit {

  main_s_en_data={ items:0,slug:null,name:null}
  main_s_en=[]
  main_s_ar_data={ items:0,slug:null,name:null}
  main_s_ar=[]
  office_m_list=['control','power','accessories']
  constructor(private toastr: ToastrService,private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.read()
  }
  save(){
   
    let ref = firebase.database().ref('main_data').child('editos')
    ref.child('en').set(this.main_s_en).then(()=>{
      this.toastr.success('The new data was saved successfully')
    })
    ref.child('ar').set(this.main_s_ar).then(()=>{
      this.toastr.success('The new data was saved successfully')
    })
  }
  read(){
    let ref = firebase.database().ref('main_data').child('editos')
    ref.child('en').once('value',snapshot=>{
      if(snapshot.val()){
        this.main_s_en=snapshot.val()

      }
    })
    ref.child('ar').once('value',snapshot=>{
      if(snapshot.val()){
        this.main_s_ar=snapshot.val()

      }
    })
  }
  add_main_s_en(){
   
    this.main_s_en.push(this.main_s_en_data)
    this.main_s_en_data={ items:0,slug:null,name:null}
    
  }
  remove_main_s_en(element){
    let dsData = this.main_s_en;
    const itemIndex = dsData.findIndex(obj => obj['name'] === element.name);
    this.main_s_en.splice(itemIndex, 1);
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }

  add_main_s_ar(){
   
    this.main_s_ar.push(this.main_s_ar_data)
    this.main_s_ar_data={ items:0,slug:null,name:null}
    
  }
  remove_main_s_ar(element){
    let dsData = this.main_s_ar;
    const itemIndex = dsData.findIndex(obj => obj['name'] === element.name);
    this.main_s_ar.splice(itemIndex, 1);
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }


  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  delete_data_en
  init_delete_en(event){
    this.delete_data_en=null
    this.delete_data_en=event
    let id = "delete_plane_window_en"
    let go = document.getElementById(id)
    go.click()
  
  }
  delete_data_ar
  init_delete_ar(event){
    this.delete_data_ar=null
    this.delete_data_ar=event
    let id = "delete_plane_window_ar"
    let go = document.getElementById(id)
    go.click()

  
  }
}

