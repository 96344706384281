import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();
    closeResult: string;
    pdf_url
    contact_list={twitter:null,map:null,'whatsapp':null,'facebook':null,instagram:null,youtube:null,skype:null,phone1:null,phone2:null,phone3:null,
    phone4:null,phone1_title:null,phone2_title:null,phone3_title:null,phone4_title:null,email:null,location:null}
 year
 subscriber={date:null,mail:null,name:null,address:null,phone_number:null}
 data_check:boolean=false

    constructor(private router: Router ,private modalService: NgbModal,private toastr: ToastrService,) {}

    ngOnInit() {
        this.get_lang()
        this.get_profile()
        this.read()
        
        let date =  new Date(Date.now());
        this.year=date.getFullYear()

    }
    langstatus
    dir
    lang_view
    language
   get_lang(){
    this.langstatus=localStorage.getItem('language')
    this.dir=localStorage.getItem('dir')
    this.language=LanguagesService.language.navbar
    switch (this.langstatus) {
      case 'en':
        this.lang_view="English"
        break;
        case 'ar':
          this.lang_view="العربية"
          break;
            case 'tr':
              this.lang_view="Turkçe"
              break;
    }
  }
    getPath(){
      return this.router.url;
    }
    open(content, type, modalDimension) {
      if (modalDimension === 'sm' && type === 'modal_mini') {
          this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = 'Closed with: $result';
          }, (reason) => {
              this.closeResult = 'Dismissed $this.getDismissReason(reason)';
          });
      } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
      } else {
          this.modalService.open(content,{ centered: true }).result.then((result) => {
              this.closeResult = 'Closed with: $result';
          }, (reason) => {
              this.closeResult = 'Dismissed $this.getDismissReason(reason)';
          });
      }
    }
  
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  'with: $reason';
        }
    }
  
    get_profile(){
        let ref11 = firebase.database().ref('template').child('profile')
            ref11.once('value',snapshot=>{
              this.pdf_url=snapshot.val()
            })
      }
      open_profile(){
        window.open(this.pdf_url, "_blank");
      
      }

      read(){
        let ref = firebase.database().ref('contact-us')
       ref.once('value',snapshot=>{
          if(snapshot.exists()){
            this.contact_list=snapshot.val()
          
          }
     
        })
      }
      open_tab(){
        let id = "subscib_0"
        let go = document.getElementById(id)
        go.click()
      }
      ValidateEmail() 
      {
        if(this.subscriber.mail  && this.subscriber.name){
          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.subscriber.mail))
          {
            this.add_subscriber()
          }else{
            this.toastr.error(this.language.error_mail)
        }
        }else{
          this.toastr.error(this.language.input_data)
          this.data_check=true
      }
    
      }
      add_subscriber(){
        this.subscriber.date=Date.now()
        let ref_test = firebase.database().ref('subscribers').orderByChild('mail').equalTo(this.subscriber.mail)
        let ref = firebase.database().ref('subscribers')
       
        
          ref_test.once('value',snapshot=>{
         
         
         
             
          if(snapshot.exists()){
            this.toastr.warning(this.language.email_existed)
          
          }else{
            ref.push(this.subscriber).then(()=>{
              this.toastr.success(this.language.newsletter_subscribed)
             
            }).then(()=>{
              
              let id = "close_window"
              let go = document.getElementById(id)
              go.click()
              this.subscriber={date:null,mail:null,name:null,address:null,phone_number:null}

            })
          }
          
          }).catch(error=>console.log(error.message))
        
      }
}
