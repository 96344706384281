import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AdminPermissionsGuard implements CanActivate {
  constructor(private router : Router,private toastr: ToastrService,){}
  canActivate(): Promise<boolean >  {
    //  let user_f = JSON.parse(localStorage.getItem('user'))
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user =>{
        if(user){
       
          firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
      
         
             if (!!idTokenResult.claims.admin ) {
               // Show admin UI.
               
              return resolve(true)
             } else {
               // Show regular user UI.
               this.toastr.error('You do not have permission to access this section' )
          
               return resolve(false)
              }
          })
          .catch((error) => {
            //console.log(error);
          });
        
        }else{
          localStorage.setItem('user', null);
          localStorage.setItem('user_email', null);
          this.toastr.error('you are not logging in' )
          this.router.navigate(['/login'])
          
        }
       
      })
    })
 
  }
  
}
