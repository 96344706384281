import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';


import { LoginComponent } from './login/login.component';

import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';




import { AdminProgramEditComponent } from './admin/admin-programs/admin-program-edit/admin-program-edit.component';
import { AdminProgramAddComponent } from './admin/admin-programs/admin-program-add/admin-program-add.component';
import { AdminProgramListComponent } from './admin/admin-programs/admin-program-list/admin-program-list.component';

import { AdminVisionComponent } from './admin/about-sycac/admin/admin-vision/admin-vision.component';
import { AdminMissionComponent } from './admin/about-sycac/admin/admin-mission/admin-mission.component';
import { AdminValuesComponent } from './admin/about-sycac/admin/admin-values/admin-values.component';
import { AdminWhereWeWorkComponent } from './admin/about-sycac/admin/admin-where-we-work/admin-where-we-work.component';
import { AdminNewsAddComponent } from './admin/news/admin-news-add/admin-news-add.component';
import { AdminNewsEditComponent } from './admin/news/admin-news-edit/admin-news-edit.component';
import { AdminNewsListComponent } from './admin/news/admin-news-list/admin-news-list.component';
import { AdminSuccessStoriesListComponent } from './admin/success-stories/admin-success-stories-list/admin-success-stories-list.component';
import { AdminSuccessStoriesAddComponent } from './admin/success-stories/admin-success-stories-add/admin-success-stories-add.component';
import { AdminSuccessStoriesEditComponent } from './admin/success-stories/admin-success-stories-edit/admin-success-stories-edit.component';
import { AdminContactUsComponent } from './admin/admin-contact-us/admin-contact-us.component';
import { AdminStaffListComponent } from './admin/join-us/staff/admin-staff-list/admin-staff-list.component';
import { AdminStaffAddComponent } from './admin/join-us/staff/admin-staff-add/admin-staff-add.component';
import { AdminStaffEditComponent } from './admin/join-us/staff/admin-staff-edit/admin-staff-edit.component';
import { AdminVolunteeringComponent } from './admin/join-us/admin-volunteering/admin-volunteering.component';
import { SliderComponent } from './admin/slider/slider.component';
import { AdminSycacComponent } from './admin/admin-sycac/admin-sycac.component';
import { AdminTestimonialListComponent } from './admin/admin-testimonials/admin-testimonial-list/admin-testimonial-list.component';
import { AdminTestimonialAddComponent } from './admin/admin-testimonials/admin-testimonial-add/admin-testimonial-add.component';
import { AdminTestimonialEditComponent } from './admin/admin-testimonials/admin-testimonial-edit/admin-testimonial-edit.component';
import { AdminWorkplaceAddComponent } from './admin/workplaces/admin-workplace-add/admin-workplace-add.component';
import { AdminWorkplaceEditComponent } from './admin/workplaces/admin-workplace-edit/admin-workplace-edit.component';
import { AdminWorkplaceListComponent } from './admin/workplaces/admin-workplace-list/admin-workplace-list.component';
import { HomePageBlocksComponent } from './admin/home-page-blocks/home-page-blocks.component';
import { AuthGuard } from './services/auth.guard';
import { AdminPermissionsGuard } from './services/admin-permissions.guard';

import { AdminPartnerAddComponent } from './admin/partners/admin-partner-add/admin-partner-add.component';
import { AdminPartnerEditComponent } from './admin/partners/admin-partner-edit/admin-partner-edit.component';
import { AdminPartnerListComponent } from './admin/partners/admin-partner-list/admin-partner-list.component';
import { SubscibersListComponent } from './admin/subscibers-list/subscibers-list.component';
import { AdministratorComponent } from './admin/administrator/administrator.component';
import { AdminPhotosLibraryComponent } from './admin/admin-photos-library/admin-photos-library.component';

import { SectionAddComponent } from './admin/section/section-add/section-add.component';
import { SectionEditComponent } from './admin/section/section-edit/section-edit.component';
import { SectionListComponent } from './admin/section/section-list/section-list.component';
import { MemberAddComponent } from './admin/members/member-add/member-add.component';
import { MemberEditComponent } from './admin/members/member-edit/member-edit.component';
import { MemberListComponent } from './admin/members/member-list/member-list.component';
import { GalleryAddComponent } from './admin/gallery/gallery-add/gallery-add.component';
import { GalleryEditComponent } from './admin/gallery/gallery-edit/gallery-edit.component';
import { GalleryListComponent } from './admin/gallery/gallery-list/gallery-list.component';
import { SponsorAddComponent } from './admin/sponsor/sponsor-add/sponsor-add.component';
import { SponsorEditComponent } from './admin/sponsor/sponsor-edit/sponsor-edit.component';
import { SponsorListComponent } from './admin/sponsor/sponsor-list/sponsor-list.component';
import { JoinSupportAddComponent } from './admin/join-support/join-support-add/join-support-add.component';
import { JoinSupportEditComponent } from './admin/join-support/join-support-edit/join-support-edit.component';
import { JoinSupportListComponent } from './admin/join-support/join-support-list/join-support-list.component';
import { EventAddComponent } from './admin/events/event-add/event-add.component';
import { EventListComponent } from './admin/events/event-list/event-list.component';
import { EventEditComponent } from './admin/events/event-edit/event-edit.component';
import { VolunteerAddComponent } from './admin/volunteers/volunteer-add/volunteer-add.component';
import { VolunteerEditComponent } from './admin/volunteers/volunteer-edit/volunteer-edit.component';
import { VolunteerListComponent } from './admin/volunteers/volunteer-list/volunteer-list.component';
import { CareerEditComponent } from './admin/careers/career-edit/career-edit.component';
import { CareerListComponent } from './admin/careers/career-list/career-list.component';
import { CareerAddComponent } from './admin/careers/career-add/career-add.component';
import { PressAddComponent } from './admin/press/press-add/press-add.component';
import { PressEditComponent } from './admin/press/press-edit/press-edit.component';
import { PressListComponent } from './admin/press/press-list/press-list.component';
import { HomeAddComponent } from './admin/home/home-add/home-add.component';
import { HomeEditComponent } from './admin/home/home-edit/home-edit.component';
import { HomeListComponent } from './admin/home/home-list/home-list.component';
import { AdminMessagesComponent } from './admin/admin-messages/admin-messages.component';
import { VideosLibraryComponent } from './admin/videos-library/videos-library.component';
import { AboutKhashabehComponent } from './admin/about-khashabeh/about-khashabeh.component';
import { PrivacyKhashabehComponent } from './admin/privacy-khashabeh/privacy-khashabeh.component';
import { PdfLibraryComponent } from './admin/pdf-library/pdf-library.component';
import { ResourcesAddComponent } from './admin/resources/resources-add/resources-add.component';
import { ResourcesEditComponent } from './admin/resources/resources-edit/resources-edit.component';
import { ResourcesListComponent } from './admin/resources/resources-list/resources-list.component';
import { ScriptsAddComponent } from './admin/scripts/scripts-add/scripts-add.component';
import { ScriptsEditComponent } from './admin/scripts/scripts-edit/scripts-edit.component';
import { ScriptsListComponent } from './admin/scripts/scripts-list/scripts-list.component';
import { PerformanceAddComponent } from './admin/performances/performance-add/performance-add.component';
import { PerformanceEditComponent } from './admin/performances/performance-edit/performance-edit.component';
import { PerformanceListComponent } from './admin/performances/performance-list/performance-list.component';
import { ArtistAddComponent } from './admin/artists/artist-add/artist-add.component';
import { ArtistEditComponent } from './admin/artists/artist-edit/artist-edit.component';
import { ArtistListComponent } from './admin/artists/artist-list/artist-list.component';
import { WriterListComponent } from './admin/writer-list/writer-list.component';
import { DirectorListComponent } from './admin/director-list/director-list.component';
import { KindListComponent } from './admin/kind-list/kind-list.component';
import { ResoucesPerNewComponent } from './admin/resouces-performances/resouces-per-new/resouces-per-new.component';
import { ResoucesPerEditComponent } from './admin/resouces-performances/resouces-per-edit/resouces-per-edit.component';
import { ResoucesPerListComponent } from './admin/resouces-performances/resouces-per-list/resouces-per-list.component';
import { WriterListRComponent } from './admin/writer-list-r/writer-list-r.component';
import { ArtistKindListComponent } from './admin/artist-kind-list/artist-kind-list.component';
import { SoundsLibraryComponent } from './admin/sounds-library/sounds-library.component';
import { StreamingComponent } from './admin/streaming/streaming.component';
import { StreamingChatComponent } from './admin/streaming-chat/streaming-chat.component';
import { ResourcesKhashabehComponent } from './admin/resources-khashabeh/resources-khashabeh.component';
import { HomePageBlockComponent } from './admin/home-page-block/home-page-block.component';
import { SeoComponent } from './admin/seo/seo.component';
import { KindsComponent } from './admin/database-blocks/kinds/kinds.component';
import { NarratorsComponent } from './admin/database-blocks/narrators/narrators.component';
import { ResearchersComponent } from './admin/database-blocks/researchers/researchers.component';
import { TranslatorsComponent } from './admin/database-blocks/translators/translators.component';
import { EditosComponent } from './admin/database-blocks/editos/editos.component';
import { ClassificationComponent } from './admin/database-blocks/classification/classification.component';
import { PlacesComponent } from './admin/database-blocks/places/places.component';
import { TeamPageComponent } from './admin/team-page/team-page.component';
import { ProjectsList1Component } from './admin/database-blocks/projects-list1/projects-list1.component';
import { AboutProjectComponent } from './admin/about-project/about-project.component';
import { CountriesComponent } from './admin/database-blocks/countries/countries.component';
import { AboutArchiveComponent } from './admin/about-archive/about-archive.component';


//////

const routes: Routes =[
  // { path: '',   redirectTo: 'home', pathMatch: 'full' }, 


    { path: 'login',          component: LoginComponent },
    { path: 'admin', component: AdminHomeComponent,canActivate:[AuthGuard], children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard',component: AdminDashboardComponent },
      { path: 'contact-us',component: AdminContactUsComponent },
      { path: 'messages',component: AdminMessagesComponent },
      { path: 'team',component: TeamPageComponent },
      { path: 'about',component: AboutKhashabehComponent },
      { path: 'edit-project',component: AboutProjectComponent },
      { path: 'about-archive',component: AboutArchiveComponent },
      { path: 'privacy',component: PrivacyKhashabehComponent },
      { path: 'resources-page',component: ResourcesKhashabehComponent },


      // { path: 'volunteering',component: AdminVolunteeringComponent },
      // { path: 'douzan-block',component: AdminSycacComponent },
      // { path: 'slider',component: SliderComponent },
      { path: 'home-block',component: HomePageBlockComponent },
      { path: 'Photos-library',component: AdminPhotosLibraryComponent },
      { path: 'videos-library',component: VideosLibraryComponent },
      { path: 'sounds-library',component: SoundsLibraryComponent },
      { path: 'seo',component: SeoComponent },

      { path: 'PDF-library',component: PdfLibraryComponent },
      // { path: 'streaming',component: StreamingComponent },
      // { path: 'streaming-chat',component: StreamingChatComponent },

      { path: 'subscibers-list',component: SubscibersListComponent },
      { path: 'administrator',canActivate:[AdminPermissionsGuard], component: AdministratorComponent },
      { path: 'members', children: [
        { path: '', redirectTo: 'list', pathMatch: 'full' },
        { path: 'add',component: MemberAddComponent },
        { path: 'edit',component: MemberEditComponent },
        { path: 'list',component: MemberListComponent },
        { path: '**',redirectTo: 'list'}
      ]},
      // { path: 'artists', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: ArtistAddComponent },
      //   { path: 'edit',component: ArtistEditComponent },
      //   { path: 'list',component: ArtistListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      { path: 'stories', children: [
        { path: '', redirectTo: 'list', pathMatch: 'full' },
        { path: 'add',component: PerformanceAddComponent },
        { path: 'edit',component: PerformanceEditComponent },
        { path: 'list',component: PerformanceListComponent },
        { path: '**',redirectTo: 'list'}
      ]},
      // { path: 'resources-performances', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: ResoucesPerNewComponent },
      //   { path: 'edit',component: ResoucesPerEditComponent },
      //   { path: 'list',component: ResoucesPerListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'programs', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminProgramAddComponent },
      //   { path: 'edit',component: AdminProgramEditComponent },
      //   { path: 'list',component: AdminProgramListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'resources', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: ResourcesAddComponent },
      //   { path: 'edit',component: ResourcesEditComponent },
      //   { path: 'list',component: ResourcesListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
    
      // { path: 'section', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: SectionAddComponent },
      //   { path: 'edit',component: SectionEditComponent },
      //   { path: 'list',component: SectionListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'album', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: GalleryAddComponent },
      //   { path: 'edit',component: GalleryEditComponent },
      //   { path: 'list',component: GalleryListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      { path: 'sponsor', children: [
        { path: '', redirectTo: 'list', pathMatch: 'full' },
        { path: 'add',component: SponsorAddComponent },
        { path: 'edit',component: SponsorEditComponent },
        { path: 'list',component: SponsorListComponent },
        { path: '**',redirectTo: 'list'}
      ]},
      // { path: 'event', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: EventAddComponent },
      //   { path: 'edit',component: EventEditComponent },
      //   { path: 'list',component: EventListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'volunteer', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: VolunteerAddComponent },
      //   { path: 'edit',component: VolunteerEditComponent },
      //   { path: 'list',component: VolunteerListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'career', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: CareerAddComponent },
      //   { path: 'edit',component: CareerEditComponent },
      //   { path: 'list',component: CareerListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'press', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: PressAddComponent },
      //   { path: 'edit',component: PressEditComponent },
      //   { path: 'list',component: PressListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'home_page', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: HomeAddComponent },
      //   { path: 'edit',component: HomeEditComponent },
      //   { path: 'list',component: HomeListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      { path: 'database-block', children: [
        { path: '', redirectTo: 'kinds', pathMatch: 'full' },
        { path: 'projects',component: ProjectsList1Component },   
        { path: 'kinds',component: KindsComponent },
        { path: 'narrators',component: NarratorsComponent },
        { path: 'researchers',component: ResearchersComponent },
        { path: 'translators',component: TranslatorsComponent },
        { path: 'editors',component: EditosComponent },
        { path: 'classification',component: ClassificationComponent },
        { path: 'countries',component: CountriesComponent },

        { path: 'place',component: PlacesComponent },
        { path: '**',redirectTo: 'kinds'}
      ]},
      // { path: 'about-douzan', children: [
      //   { path: '', redirectTo: 'where-we-work', pathMatch: 'full' },
      //   { path: 'where-we-work',component: AdminWhereWeWorkComponent },
      //   { path: 'missions',component: AdminMissionComponent },
      //   { path: 'visions',component: AdminVisionComponent },
      //   { path: 'values',component: AdminValuesComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
   
      

      // { path: 'success-stories', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminSuccessStoriesAddComponent },
      //   { path: 'edit',component: AdminSuccessStoriesEditComponent },
      //   { path: 'list',component: AdminSuccessStoriesListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'join-us-staff', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminStaffAddComponent },
      //   { path: 'edit',component: AdminStaffEditComponent },
      //   { path: 'list',component: AdminStaffListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'testimonials', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminTestimonialAddComponent },
      //   { path: 'edit',component: AdminTestimonialEditComponent},
      //   { path: 'list',component: AdminTestimonialListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'workplaces', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminWorkplaceAddComponent },
      //   { path: 'edit',component: AdminWorkplaceEditComponent},
      //   { path: 'list',component: AdminWorkplaceListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
      // { path: 'partners', children: [
      //   { path: '', redirectTo: 'list', pathMatch: 'full' },
      //   { path: 'add',component: AdminPartnerAddComponent },
      //   { path: 'edit',component: AdminPartnerEditComponent},
      //   { path: 'list',component: AdminPartnerListComponent },
      //   { path: '**',redirectTo: 'list'}
      // ]},
    ]},
   { path: '**',redirectTo: 'admin'}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
