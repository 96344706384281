import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  cardslist
  Ref_data = firebase.database().ref('testimonial')

  constructor() {
    this.cardslist = Array(3).fill(0).map((x,i)=>i+1);
   }

  ngOnInit(): void {
    this.get_lang()
    this.get_testimonial()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}
  testimonial_list=[]
  get_testimonial(){
    this.testimonial_list=[]
  
  this.Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  let y = snapshot.val()
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleDateString()
  
  y.key = snapshot.key
  this.testimonial_list.push(y)
    })
  }).then(()=>{
    this.testimonial_list.reverse()
  })
  }
}
