import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css']
})
export class AdminNavbarComponent implements OnInit {
user
  constructor(private toastr: ToastrService,private router:Router,) { }

  ngOnInit(): void {
    this.user=VariablesService.user
  }
  logout(){
  
 
    let ref =firebase.database().ref('logs/')
  

    firebase.auth().signOut().then(()=> {
      // Sign-out successful.
  
      this.toastr.success('Sign-out successful' )
      this.router.navigate(['login'])
    }).catch((error)=> {
      this.toastr.error(error.code)
    });
  }
}
