import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-press-add',
  templateUrl: './press-add.component.html',
  styleUrls: ['./press-add.component.css']
})
export class PressAddComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  press_data={date:0,name_en:null,name_ar:null,
    subtitle_en:null,subtitle_ar:null,
    excerpt_en:null,excerpt_ar:null,
  
    type_en:null,type_ar:null,
    caption_ar:null,caption_en:null,
    img_url:null,img_name:null,
    link:null,p_date:0,
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    
  }

  init_upload(){
    if(this.press_data.name_en  && this.press_data.name_ar 
      && this.press_data.subtitle_en  && this.press_data.subtitle_ar
      && this.press_data.type_en  && this.press_data.type_ar
      && this.press_data.excerpt_en &&  this.press_data.excerpt_ar
    
      && this.press_data.img_url){
        this.upload()
      }else{
        this.toastr.error('You should input all  data')
        this.data_check=true
      }
  }


  upload(){
    this.time_now = Date.now()
    
    this.press_data.date=this.time_now
    
    
    
    ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
    let Ref_data = firebase.database().ref('press'); //new
    Ref_data.push(this.press_data).then(()=>{
      this.toastr.success('Upload finished, The press added pressfully. ')
  
    this.router.navigate(['/admin/press/list'])
   
    })
  }
 
  init_add_img(){
    let id = "add_img_section_0"
    let go = document.getElementById(id)
    go.click()
  
  }



  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }





}
