import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import  firebase from "firebase/app";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.css']
})
export class AdministratorComponent implements AfterViewInit {
  edit_s:boolean=false
  canceled="#f5365c"
  in_progress="#5e72e4"
  waiting="#ffea31"
  finished="#2dce89"
  user_info={a_id:null,uid:'',email:null,emailverified:'',f_name:'',l_name:'',date_birth:'',phone:null,company_name:'',skype_name:'',chiptuning_tool:'',user_name:'',address:'',city:'',country:'',postal_code:'',about:'',img_url:'',a1:'',a2:'',a3:0}

  customers=[]
  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['name', 'email' ,'role','date','Btn']
 Ref_data = firebase.database().ref('admin/users')

    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('idtable') idtable: TemplateRef<any>;
  elementwindow=[]
  closeResult: string;
 plan={date:null,pl_id:null,name:null, description:null}
  constructor(private router: Router,private modalService: NgbModal,private toastr: ToastrService) {
    this.get_administrator()
   }

  ngAfterViewInit(): void {
  
    // throw new Error('Method not implemented.');
    this.dataSource.paginator = this.paginator;
     
     this.dataSource.sort = this.sort;
    
   }
 
   
   open(content, type, modalDimension) {
     if (modalDimension === 'sm' && type === 'modal_mini') {
         this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
             this.closeResult = 'Closed with: $result';
         }, (reason) => {
             this.closeResult = 'Dismissed $this.getDismissReason(reason)';
         });
     } else if (modalDimension === '' && type === 'Notification') {
       this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
           this.closeResult = 'Closed with: $result';
       }, (reason) => {
           this.closeResult = 'Dismissed $this.getDismissReason(reason)';
       });
     } else {
         this.modalService.open(content,{ centered: true }).result.then((result) => {
             this.closeResult = 'Closed with: $result';
         }, (reason) => {
             this.closeResult = 'Dismissed $this.getDismissReason(reason)';
         });
     }
   }
 
   private getDismissReason(reason: any): string {
       if (reason === ModalDismissReasons.ESC) {
           return 'by pressing ESC';
       } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
           return 'by clicking on a backdrop';
       } else {
           return  'with: $reason';
       }
   }

   applyFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;
     this.dataSource.filter = filterValue.trim().toLowerCase();
 
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }
   do_filter() {
    const filterValue = this.filter
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(filterValue)

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

administrator_list=[]
get_administrator(){


this.Ref_data.on('value',snapshots=>{
  this.administrator_list=[]
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleString()

y.key = snapshot.key
this.administrator_list.push(y)
this.dataSource = new MatTableDataSource(this.administrator_list)
this.dataSource.sort = this.sort;
this.dataSource.paginator = this.paginator;
  })
})
// .then(()=>{
//   this.administrator_list.reverse()
//   // this.plan_list.sort(comparenameup)
//   this.dataSource = new MatTableDataSource(this.administrator_list)
//   this.dataSource.sort = this.sort;
//   this.dataSource.paginator = this.paginator;
// })
}


data_check:boolean=true
password_s=null
ppp
  admin={id:null,date:0,name:null,email:null,admin:null,editor:null,owner:null,password:null}
  add_new_init(){
    this.edit_s=false
    this.admin={id:null,date:0,name:null,email:null,admin:null,editor:null,owner:null,password:null}
    this.password_s=null
    let id = "new_plane_window"
    let go = document.getElementById(id)
    go.click()
  
  
  }


  add_new(){
    if(this.admin.password == this.password_s && this.admin.password){
      if(this.admin.name && this.admin.email && this.ppp){
        const id0 = Math.random().toString(36).substring(2)
        switch (this.ppp) {
          case '1':
           this.admin.admin=true
           this.admin.editor=false
            break;
            case '2':
             this.admin.admin=false
             this.admin.editor=true
              break;
        }
        this.admin.id=id0
        this.admin.date =Date.now()
    let ref_plane = firebase.database().ref('admin/new-user')
    ref_plane.push(this.admin).then(()=>{
     
    this.toastr.success('The new user added successfully,wait just 20 seconds to proccessing the data .')
    this.admin={id:null,date:0,name:null,email:null,admin:null,editor:null,owner:null,password:null}
    this.password_s=null
    }).then(()=>{
      let id = "close_window"
    let go = document.getElementById(id)
    go.click()
      this.get_administrator()
    })
      }else{
        this.data_check=false
        this.toastr.warning('You must input all data')
      }
    }else{
      this.data_check=false
      this.toastr.warning('You must input all data')
      this.toastr.warning('You must input the same password')
    }
  

  }
  plane_edit_data
 


  


administrator_delete_data
  init_delete(event){
    let id = "delete_plane_window"
    let go = document.getElementById(id)
    go.click()
    this.administrator_delete_data=event
  
  }

  go_delete(){
    // let ref = firebase.database().ref('acc').child('plans/'+this.administrator_delete_data.key)
    this.Ref_data.child(this.administrator_delete_data.key).remove().then(()=>{
     
    this.toastr.success('The administrator removed successfully')
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
      this.get_administrator()
      this.administrator_delete_data=null
    })
  }

 
  edit_init(element){
    this.edit_s=true
    this.password_s = element.password
    this.admin = element
    let id = "new_plane_window"
    let go = document.getElementById(id)
    go.click()
  }
  async resetpas(event){
    
    firebase.auth().sendPasswordResetEmail(event.email).catch(error=>{
      this.toastr.error(error.message)

    }).then(()=>{
    
      this.toastr.success('The link of reset password sended to the email')
   
    })
  
}
}


export interface PeriodicElement {
  cid:number,vehicle_type:string,Make:string,Model:string,date:number,Engine:string,ECU:string,Credits:number, }
 export class ButtonOverviewExample {}
 const ELEMENT_DATA: PeriodicElement[] = [
  //  {    cid:1,vehicle_type:'car',Make:'bmw',Model:'m3',date:343432423,Engine:'ppr433e',ECU:'siemens',Credits:10},
 
];


function comparenameup(a, b) {
  const genreA = a.date;
  const genreB = b.date;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison* -1;
}