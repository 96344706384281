import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { Toast, ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-page-blocks',
  templateUrl: './home-page-blocks.component.html',
  styleUrls: ['./home-page-blocks.component.css']
})
export class HomePageBlocksComponent implements OnInit {
  main_s_data={url:null,title:null,discount:0}
  main_d_data={icon:null,title_en:null,title_tr:null,title_ar:null,number:0}
  main_s=[]
  constructor(private toastr: ToastrService,private modalService: NgbModal,) { }
data_numbers={targeted_people:null,work_areas:null,implemented_projects:null,number_exercises:null,dialogue_sessions:null,
  forums:null,targeted_women:null,special_needs:null}
  ngOnInit(): void {
 this.read()
  }
  read1(){
    let ref = firebase.database().ref('template/home-blocks')
   ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.data_numbers=snapshot.val()
      }
 
    })
  }

  save1(){
    let ref = firebase.database().ref('template/home-blocks')
    ref.set(this.data_numbers).then(()=>{
      this.toastr.success('The data added successfully')
    })
  }


  save(){
    let ref = firebase.database().ref('main_data').child('brands')
    ref.set(this.main_s).then(()=>{
      this.toastr.success('The new data was saved successfully')
    })
  }
  read(){
    let ref = firebase.database().ref('main_data').child('brands')
    ref.once('value',snapshot=>{
      if(snapshot.val()){
        this.main_s=snapshot.val()

      }
    })
  }
  add_main_s(){
   
    this.main_s.push(this.main_d_data)
    this.main_d_data={icon:null,title_en:null,title_tr:null,title_ar:null,number:0}
    
  }
  remove_main_s(element){
    let dsData = this.main_s;
    const itemIndex = dsData.findIndex(obj => obj['url'] === element.url);
    this.main_s.splice(itemIndex, 1);
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
  }


  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  delete_data
  init_delete(event){
    this.delete_data=null
    let id = "delete_plane_window"
    let go = document.getElementById(id)
    go.click()
    this.delete_data=event
  
  }
}
