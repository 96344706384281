import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  data={documents:0,performances:0,texts:0,artists:0,resources:0,kinds:0,directors:0,writers:0}
  constructor() { }

  ngOnInit(): void {
    this.performances()
    this.texts()
    this.Documents()
    this.artists()
    this.resources()
    this.kinds()
    this.directors()
    this.writers()
  }
  performances(){
    let performance_data = firebase.database().ref('performance')
      performance_data.once('value',snapshot=>{
        this.data.performances=snapshot.numChildren()
      })

  }
  texts(){
    let performance_data = firebase.database().ref('script')
      performance_data.once('value',snapshot=>{
        this.data.texts=snapshot.numChildren()
      })

  }
  Documents(){
    let performance_data = firebase.database().ref('album')
      performance_data.once('value',snapshot=>{
        this.data.documents=snapshot.numChildren()
      })

  }
  resources(){
    let performance_data = firebase.database().ref('resource')
      performance_data.once('value',snapshot=>{
        this.data.resources=snapshot.numChildren()
      })

  }
  artists(){
    let performance_data = firebase.database().ref('artist')
      performance_data.once('value',snapshot=>{
        this.data.artists=snapshot.numChildren()
      })

  }
  kinds(){
    let performance_data = firebase.database().ref('main_data/kind')
      performance_data.once('value',snapshot=>{
        this.data.kinds=snapshot.numChildren()
      })

  }
  writers(){
    let performance_data = firebase.database().ref('main_data/writer')
      performance_data.once('value',snapshot=>{
        this.data.writers=snapshot.numChildren()
      })

  }
  directors(){
    let performance_data = firebase.database().ref('main_data/director')
      performance_data.once('value',snapshot=>{
        this.data.directors=snapshot.numChildren()
      })

  }
}
