import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import firebase from 'firebase';
import { VariablesService } from './services/variables.service';
import { LanguagesService } from './services/languages.service';

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    totops:boolean=false
    topPosToStartaddlist = 700;
    constructor(public language:LanguagesService, private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location) {}
    @HostListener('window:scroll', ['$event'])
    hasScrolled() {

        var st = window.pageYOffset;
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        var navbar = document.getElementsByTagName('nav')[0];

        // If they scrolled down and are past the navbar, add class .headroom--unpinned.
        // This is necessary so you never see what is "behind" the navbar.
        // if (st > lastScrollTop && st > navbarHeight){
        //     // Scroll Down
        //     if (navbar.classList.contains('headroom--pinned')) {
        //         navbar.classList.remove('headroom--pinned');
        //         navbar.classList.add('headroom--unpinned');
        //     }
        // } else {
       
        //     if(st + window.innerHeight < document.body.scrollHeight) {
        //         if (navbar.classList.contains('headroom--unpinned')) {
        //             navbar.classList.remove('headroom--unpinned');
        //             navbar.classList.add('headroom--pinned');
        //         }
        //     }
        // }

        lastScrollTop = st;
    };
    logic
    ngOnInit() {
        this.language.getUsersLocale()
        this.get_lang_0()
      var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          if (window.outerWidth > 991) {
              window.document.children[0].scrollTop = 0;
          }else{
              window.document.activeElement.scrollTop = 0;
          }
          this.renderer.listen('window', 'scroll', (event) => {
              const number = window.scrollY;
            //   if (number > 150 || window.pageYOffset > 150) {
            //       // add logic

            //       navbar.classList.add('headroom--not-top');
            //   } else {
            //       // remove logic
            //       navbar.classList.remove('headroom--not-top');
            //   }
          });
      });
      this.hasScrolled();
    }
    dir
    language_0
    lang
   get_lang_0(){
     this.lang= localStorage.getItem('language')
    this.dir=localStorage.getItem('dir')
    this.language_0=LanguagesService.language.navbar
   }
    @HostListener('window:scroll')
   
    checkScroll() {
  
     
      // window의 scroll top
      // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
  ////console.log(document.documentElement.offsetHeight,'one')
  ////////console.log( window.innerHeight)
  ////console.log(window.pageYOffset)
  ////console.log( window.innerHeight+ document.documentElement.scrollTop + this.topPosToStartaddlist)
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
   
    
     ////console.log(scrollPosition)
     if (scrollPosition    > this.topPosToStartaddlist ) {
     
      this.totops=true
      
       } else{
         this.totops=false
       }
      if (document.documentElement.offsetHeight    <= window.innerHeight + document.documentElement.scrollTop + this.topPosToStartaddlist ) {
    
      } else{
       
      }
    }
    gotoTop() {
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      }
     
    
}
