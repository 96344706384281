import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  pdf_url
  constructor() { }

  ngOnInit(): void {
    this.get_lang()
    this.get_progile()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}
get_progile(){
  let ref11 = firebase.database().ref('template').child('profile')
      ref11.once('value',snapshot=>{
        this.pdf_url=snapshot.val()
      })
}
open_profile(){
  window.open(this.pdf_url, "_blank");

}
}
