import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.css']
})
export class NewsViewComponent implements OnInit {
  id
  id1
  show:boolean=false
  news_data={date:0,date1:null,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    body_en:null,body_tr:null,body_ar:null,
    img_url:null,img_name:null}
  constructor(private router: Router,protected sanitizer: DomSanitizer,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params['id']
    this.id1=this.route.snapshot.queryParamMap.get('id')
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.get_news()
    this.get_lang()
  
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.news

}
  get_news(){
    if(this.id1){
  // let key = VariableService.ecu_service.key
  let ref = firebase.database().ref('news').child(this.id1)
      ref.once('value',snapshots=>{

        if(snapshots.exists()){
       
            this.news_data=snapshots.val()
            this.news_data.body_en= this.transform(this.news_data.body_en)
            this.news_data.body_tr= this.transform(this.news_data.body_tr)
            this.news_data.body_ar= this.transform(this.news_data.body_ar)
            var datep = new Date(this.news_data.date);
            this.news_data.date1=  datep.toLocaleDateString()
        
        
        }else{
          this.router.navigate(['/news'])
            }
      
      }).then(()=>{
        this.get_other_news()
        this.show=true
      })
    }
  }
news_list=[]
get_other_news(){
  this.news_list=[]
let  Ref_data = firebase.database().ref('news').orderByKey().limitToLast(4)

Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
if(y.name_en != this.news_data.name_en){
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleDateString()
  
  y.key = snapshot.key
  this.news_list.push(y)
}

  })
})
}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  read_more(event){
    // VariablesService.project=event
     
    this.router.navigate(['news-events/'+event.name_en])

  }
 
}
