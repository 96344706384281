import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef

  constructor(protected sanitizer: DomSanitizer) { }

  contact_list={twitter:null,map:null,'whatsapp':null,'facebook':null,instagram:null,youtube:null,skype:null,phone1:null,phone2:null,phone3:null,
  phone4:null,phone1_title:null,phone2_title:null,phone3_title:null,phone4_title:null,email:null,location:null}
    ngOnInit(): void {


   this.read()
   this.get_lang()
  
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.join_us

}
    read(){
      let ref = firebase.database().ref('contact-us')
     ref.once('value',snapshot=>{
        if(snapshot.exists()){
          this.contact_list=snapshot.val()
          this.contact_list.map=this.transform(this.contact_list.map)
        }
   
      })
    }
    transform(htmlString: string): any {
      // return this.sanitizer.bypassSecurityTrustHtml(htmlString);
      return this.sanitizer.bypassSecurityTrustResourceUrl(htmlString);

     
    }
}
