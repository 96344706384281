import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-currency-exchange',
  templateUrl: './currency-exchange.component.html',
  styleUrls: ['./currency-exchange.component.css']
})
export class CurrencyExchangeComponent implements OnInit {

  constructor(private toastr: ToastrService,) { }
data_numbers={targeted_people:null,work_areas:null,implemented_projects:null,number_exercises:null,dialogue_sessions:null,
  forums:null,targeted_women:null,special_needs:null}
  ngOnInit(): void {
 this.read()
  }
  read(){
    let ref = firebase.database().ref('template/home-blocks')
   ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.data_numbers=snapshot.val()
      }
 
    })
  }

  save(){
    let ref = firebase.database().ref('template/home-blocks')
    ref.set(this.data_numbers).then(()=>{
      this.toastr.success('The data added successfully')
    })
  }
}
