import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resources-khashabeh',
  templateUrl: './resources-khashabeh.component.html',
  styleUrls: ['./resources-khashabeh.component.css']
})
export class ResourcesKhashabehComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  program_data={date:0,
    body_en:null,body_ar:null
    }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.read_data()
  }
  read_data(){
  
    
    let ref_test = firebase.database().ref('pages/resources-page')
    ref_test.once('value',snapshot=>{
      if(snapshot.exists()){
        this.program_data=snapshot.val()
      }
    })
  }
  init_upload(){
    if(this.program_data.body_en && this.program_data.body_en && this.program_data.body_en){
        let id = "uploading_window"
      let go = document.getElementById(id)
      go.click()
        
      }else{
        this.toastr.error('You should input all necessary data')
        this.data_check=true
      }
  }

 
  upload(){
    
    this.time_now = Date.now()
    this.program_data.date=this.time_now
    
    let ref_test = firebase.database().ref('pages/resources-page')

    ref_test.set(this.program_data).then(()=>{
      this.toastr.success('Upload finished, The Data added successfully. ')
      let id = "close_window"
      let go = document.getElementById(id)
      go.click()
    // this.router.navigate(['admin/blogs/list'])
   
    

    })
    
  
    
   
  
  }
  



  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }

}
