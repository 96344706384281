import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  id
  show:boolean=false
  project_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    body_en:null,body_tr:null,body_ar:null,progress:0,duration:0,target:0,
    img_url:null,img_name:null,program :null}
  constructor(private titleService: Title, private metaService: Meta,private router: Router,protected sanitizer: DomSanitizer,private route:ActivatedRoute) { }
  ngOnInit(): void {
    this.id=this.route.snapshot.params['id']
    // this.id = decodeURIComponent(this.id)
 
  //   this.route.params.subscribe(params => {
  //     this.param = params['yourParam'];
  //     this.initialiseState(); // reset and set based on new parameter this time
  // });
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  this.get_lang()
    this.get_project()
    this.get_lang()
  
  }
  start(){
    
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.project

}
  get_project(){
    if(this.id){
  // let key = VariableService.ecu_service.key
  let ref = firebase.database().ref('projects').orderByChild('name_en').equalTo(this.id).limitToLast(1)
      ref.once('value',snapshots=>{

        if(snapshots.exists()){
          snapshots.forEach(snapshot=>{
            this.project_data=snapshot.val()
            this.project_data.body_en= this.transform(this.project_data.body_en)
            this.project_data.body_tr= this.transform(this.project_data.body_tr)
            this.project_data.body_ar= this.transform(this.project_data.body_ar)

          })
        
        }else{
          this.router.navigate(['/home'])
            }
      
      }).then(()=>{
        this.get_news()
        this.show=true
      })
    }
  }
news_list=[]
get_news(){
  this.news_list=[]
  let  Ref_data = firebase.database().ref('projects').orderByChild('program').equalTo(this.project_data.program)

Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
   
let y = snapshot.val()
if(y.name_en != this.project_data.name_en){
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleDateString()
  
  y.key = snapshot.key
  this.news_list.push(y)
}

  })
})
}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
  read_more(event){
    VariablesService.project=event
     
    this.router.navigate(['project/'+event.name_en])

  }
  
}
