import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  constructor() { 
    this.get_places()
  }

  ngOnInit(): void {
    this.get_lang()
    this.get_places()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}

  place_list=[]
  get_places(){
    this.place_list=[]
  let  ref = firebase.database().ref('places')
  ref.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  let y = snapshot.val()
  var date1 = new Date(y.date);
  y.Date=  date1.toLocaleString()
  y.lat=+y.lat
  y.lng=+y.lng
  y.key = snapshot.key
  this.place_list.push(y)
    })
  })
  }
}
