import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';

@Component({
  selector: 'app-photos-library',
  templateUrl: './photos-library.component.html',
  styleUrls: ['./photos-library.component.css']
})
export class PhotosLibraryComponent implements OnInit {
  closeResult: string;
  img_list=[]
  img_list_f=[]
  img_num_page=20
  img_num_plus=20
  show:boolean=false
    constructor(private modalService: NgbModal) { }
Items = [
    { img_url:'/assets/img/img/164828422_2064878533664700_5290555813646262561_n.jpg' },
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/164828422_2064878533664700_5290555813646262561_n.jpg' },
    { img_url:'/assets/img/img/139442663_2008846022601285_4794184959462755040_n.jpg'},
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/168839737_2072192482933305_7093472554407998083_n.jpg'},
    { img_url:'/assets/img/img/139442663_2008846022601285_4794184959462755040_n.jpg'},
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},
    
    { img_url:'/assets/img/img/168839737_2072192482933305_7093472554407998083_n.jpg'},
    { img_url:'/assets/img/img/139442663_2008846022601285_4794184959462755040_n.jpg'},
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
    { img_url:'/assets/img/img/169157581_2073006599518560_5746034165846710206_n.jpg'},
    { img_url:'/assets/img/img/UN0321665_1.jpg'},

    { img_url:'/assets/img/img/164828422_2064878533664700_5290555813646262561_n.jpg' },
    { img_url:'/assets/img/img/139442663_2008846022601285_4794184959462755040_n.jpg'},
    { img_url:'/assets/img/img/UN0398428_1.jpg'},
  ];
  ngOnInit(): void {
    this.get_projects()
    this.get_lang()
   
}
langstatus
dir
language
get_lang(){
this.langstatus=localStorage.getItem('language')
this.dir=localStorage.getItem('dir')
this.language=LanguagesService.language.other

}

  get_projects(){
   
  let  Ref_data = firebase.database().ref('projects')
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  this.img_list.push(snapshot.val())
    })
  }).then(()=>{
    this.get_programs()
  })
  }

  get_programs(){
   
  let  Ref_data = firebase.database().ref('programs')
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  this.img_list.push(snapshot.val())
    })
  }).then(()=>{
    this.get_news()
  })
  }

  get_news(){
   
  let  Ref_data = firebase.database().ref('news')
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  this.img_list.push(snapshot.val())
    })
  }).then(()=>{
    this.get_success()
  })
  }

  get_success(){
   
  let  Ref_data = firebase.database().ref('success')
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  this.img_list.push(snapshot.val())
    })
  }).then(()=>{
    this.get_testimonial()
  })
  }

  get_testimonial(){
   
  let  Ref_data = firebase.database().ref('testimonial')
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  this.img_list.push(snapshot.val())
    })
  }).then(()=>{
    this.order_by_date()

  })
  }

  order_by_date(){
    this.img_list.sort(comparenameup)
    let ii=0
    for(let index of this.img_list){
        index.ii=ii
       if(ii<this.img_num_page){
           this.img_list_f.push(index)
       }
        ii=ii+1
    }

    this.show=true  
}

  show_more(){
      let last_num=this.img_num_page+this.img_num_plus
    for(let index of this.img_list){
       
       if(this.img_num_page<index.ii && index.ii<=last_num){
          
           this.img_list_f.push(index)
       }
       
    }
    this.img_num_page=this.img_num_page+this.img_num_plus
  }
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
  image_large
  open_img(event){
    this.image_large=null
    this.image_large=event
    let id = "submit_window"
  let go = document.getElementById(id)
  go.click()
  }
}
function comparenameup(a, b) {
    const genreA = a.date;
    const genreB = b.date;
  
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison* -1;
  }