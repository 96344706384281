import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-programs-page',
  templateUrl: './programs-page.component.html',
  styleUrls: ['./programs-page.component.css']
})
export class ProgramsPageComponent implements OnInit {
  cardslist
  news_list=[]
  news_list_f=[]
  news_one_page=9
  total_page_num=0
  page_list
  now_page
  show:boolean=false
  constructor(private router: Router) { 
    this.cardslist = Array(9).fill(0).map((x,i)=>i+1);
  }

  ngOnInit(): void {
    this.get_news()
    this.get_lang()
  
  }
  langstatus
  dir
  language
  language1
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.news
  this.language1=LanguagesService.language.navbar
}
  get_news(){
    this.show=true
    this.news_list=[]
  let  Ref_data = firebase.database().ref('programs')
 
  Ref_data.once('value',snapshots=>{
    snapshots.forEach(snapshot=>{
  let y = snapshot.val()

    var date1 = new Date(y.date);
    y.date=  date1.toLocaleDateString()
    
    y.key = snapshot.key
    this.news_list.push(y)
  
  
    })
  }).then(()=>{
    this.news_list.reverse()
    console.log(this.news_list)
    let num = 1
    for(let index of this.news_list){ 
      index.num=num
      num = num+1
    }
    this.total_page_num=Math.floor(this.news_list.length/this.news_one_page)+1
    if(  this.total_page_num<1){
      this.total_page_num=1
      
    }
    this.page_list = Array(this.total_page_num).fill(this.total_page_num).map((x,i)=>i+1);
    this.go_page(1)
 
  })
  }
go_page(num_page){
  this.now_page=num_page
    this.show=true
    setTimeout(()=>this.show=false,1000)
    this.gotoTop()
  this.news_list_f=[]
let first_num=1
let last_num=this.news_one_page
let first_num_f=0
let last_num_f=0
if(num_page==1){
  first_num_f=first_num
  last_num_f=last_num
  
}else{
  first_num_f=first_num+((num_page-1)*this.news_one_page)
  last_num_f=last_num+((num_page-1)*this.news_one_page)
}
for(let index of this.news_list){
  if(index.num >= first_num_f && index.num <= last_num_f){
    this.news_list_f.push(index)
  }
}
}

next_page(){
 
  this.go_page(this.now_page+1)
}
Previous_page(){
  this.go_page(this.now_page-1)
}
  read_more(event){
    // VariablesService.project=event
     
    this.router.navigate(['news-events/'+event.name_en])

  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  go_program(event){
    // VariablesService.program=event
    this.router.navigate(['/program/'+event.acronym])
}
}
