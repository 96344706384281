import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from 'src/app/services/languages.service';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.css']
})
export class LatestNewsComponent implements OnInit {
  cardslist
  Ref_data = firebase.database().ref('news').orderByKey().limitToLast(3)
  constructor(private router: Router) {
    this.cardslist = Array(3).fill(0).map((x,i)=>i+1);
   }

  ngOnInit(): void {
    this.get_lang()
    this.get_news()
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.home_page

}

news_list=[]
get_news(){
  this.news_list=[]

this.Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleDateString()

y.key = snapshot.key
this.news_list.push(y)
  })
}).then(()=>{
  this.news_list.reverse()
})
}

read_more(event){
  VariablesService.project=event
  this.router.navigate(['news-events/'+event.name_en],{queryParams:{id:event.key}})


}
}
