import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { Router } from '@angular/router';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import  firebase from 'firebase';

import { ToastrService } from 'ngx-toastr';
import imageCompression from 'browser-image-compression';

@Component({
  selector: 'app-videos-library',
  templateUrl: './videos-library.component.html',
  styleUrls: ['./videos-library.component.css']
})
export class VideosLibraryComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  image_title
  data_check:boolean=false
  time_now
  uploading_status:boolean=false
  uploadProgress0
  uploadProgress_0
  idColumn = 'key';
  program_data={date:0,vid_url:null,vid_name:null,vid_title:null}
    /////////////////
  canceled="#f5365c"
  in_progress="#5e72e4"
  waiting="#ffea31"
  finished="#2dce89"
  user_info={a_id:null,uid:'',email:null,emailverified:'',f_name:'',l_name:'',date_birth:'',phone:null,company_name:'',skype_name:'',chiptuning_tool:'',user_name:'',address:'',city:'',country:'',postal_code:'',about:'',img_url:'',a1:'',a2:'',a3:0}

  customers=[]
  rows = 10;
  products1=[];
  filter=''
  ////
  displayedColumns: string[] = ['name' ,'uid' ,'date','btn']
 Ref_data = firebase.database().ref('videoslibrary')

    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('idtable') idtable: TemplateRef<any>;
  elementwindow=[]
  closeResult: string;
 
  constructor(private router: Router,private modalService: NgbModal,private toastr: ToastrService) {
    this.get_photoslibrary()
   }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  
    // throw new Error('Method not implemented.');
    this.dataSource.paginator = this.paginator;
     
     this.dataSource.sort = this.sort;
    
   }
   url;
   format;
   video_file
   onSelectFile(event) {
     const file = event.target.files && event.target.files[0];
     this.video_file = file
     this.image_name= Date.now()+file.name
     this.program_data.vid_name=Date.now()+file.name
    //  if (file) {
    //    var reader = new FileReader();
    //    reader.readAsDataURL(file);
    //    if(file.type.indexOf('image')> -1){
    //      this.format = 'image';
    //    } else if(file.type.indexOf('video')> -1){
    //      this.format = 'video';
    //    }
    //    reader.onload = (event) => {
    //      this.url = (<FileReader>event.target).result;
    //    }
    //  }
   }
   onSelectFile00(event) {
    var imageFile=null
    this.image_name=null
    this.spinner=true
    this.url00=null
    this.url0=null
     imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      this.image_name= Date.now()+imageFile.name
      this.program_data.vid_name=Date.now()+imageFile.name
    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then( (compressedFile) => {
       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        console.log(compressedFile);
      //  let rewq=compressedFile.size / 1024 

        this.url00=compressedFile
       // console.log('url00',this.url00)
        let reader = new FileReader()
      //  this.file=reader
        reader.readAsDataURL(compressedFile)
        reader.onload = (compressedFile) => { // called once readAsDataURL is completed
          // @ts-ignore
          this.url0 = compressedFile.target.result;
         // console.log('url0',this.url0)

this.images0=true
  this.spinner=false

}   
      }) 
     
      .catch(function (error) {
        console.log(error.message);
        
      });
       

     
  }
   open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


   applyFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;
     this.dataSource.filter = filterValue.trim().toLowerCase();
 
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
   }
   do_filter() {
    const filterValue = this.filter
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(filterValue)

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  add_new(){
    this.router.navigate(['admin/photoslibrary/add'])
  }
photoslibrary_list=[]
get_photoslibrary(){

this.Ref_data.on('value',snapshots=>{
  this.photoslibrary_list=[]

  snapshots.forEach(snapshot=>{
let y = snapshot.val()
var date1 = new Date(y.date);
y.date=  date1.toLocaleString()

y.key = snapshot.key
this.photoslibrary_list.push(y)
this.photoslibrary_list.sort(comparenameup)
this.dataSource = new MatTableDataSource(this.photoslibrary_list)
this.dataSource.sort = this.sort;
this.dataSource.paginator = this.paginator;
  })
})
// .then(()=>{
//   // this.photoslibrary_list.reverse()
//   this.photoslibrary_list.sort(comparenameup)
//   this.dataSource = new MatTableDataSource(this.photoslibrary_list)
//   this.dataSource.sort = this.sort;
//   this.dataSource.paginator = this.paginator;
// })
}



add_new_init(){

  let id = "add_photo_0"
  let go = document.getElementById(id)
  go.click()
  
}


upload(){
  if(this.video_file && this.program_data.vid_title ){

  
  this.uploading_status=true
  this.time_now = Date.now()
  let storageRef = firebase.storage().ref('videoslibrary');
  var uploadTask = storageRef.child( this.image_name).put(this.video_file)

 

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
  snapshot=> {
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  this.uploadProgress0 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  this.uploadProgress_0= Math.floor(this.uploadProgress0)
  switch (snapshot.state) {
  case firebase.storage.TaskState.PAUSED: // or 'paused'
   this.toastr.warning('Upload is paused');
    break;
  case firebase.storage.TaskState.RUNNING: // or 'running'
  this.toastr.info('Upload is running');
    break;
  }
  }, (error=> {
  
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  switch (error.message) {
  case 'storage/unauthorized':
  // User doesn't have permission to access the object
  this.toastr.error("User doesn't have permission to access the object");
  break;
  
  case 'storage/canceled':
  // User canceled the upload
  this.toastr.error("User canceled the upload");

  break;
  
  
  
  case 'storage/unknown':
  // Unknown error occurred, inspect error.serverResponse
  this.toastr.error("Unknown error occurred, inspect error.serverResponse");

  break;
  }
  }), (()=> {
  // Upload completed successfully, now we can get the download URL
  uploadTask.snapshot.ref.getDownloadURL().then((downloadURL=> {
  
  this.program_data.vid_url=downloadURL
  this.program_data.date=this.time_now
 
  
  
  ///let Ref = firebase.database().ref('users/'+this.user_info.a_id+'/orders/'); //old
  let Ref_data = firebase.database().ref('videoslibrary'); //new
  Ref_data.push(this.program_data).then(()=>{
    this.toastr.success('Upload finished, The photo added successfully. ')
    let id = "close_window"
    let go = document.getElementById(id)
    go.click()
    // this.photoslibrary_list.reverse()
    // this.photoslibrary_list.push(this.program_data)
    // this.dataSource = new MatTableDataSource(this.photoslibrary_list)
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.program_data={date:0,vid_url:null,vid_name:null,vid_title:null}
    this.url00=null
    this.url0=null
    this.uploading_status=false
  })
  
  // ).catch(error=>{
  //   console.log(error.message)
  // })
  
  }));
  }));
}else{
  this.toastr.error('You should input Photo and title')
  this.data_check=true

}
}


project_delete_data
  init_delete(event){
    let id = "delete_project_0"
    let go = document.getElementById(id)
    go.click()
    this.project_delete_data=event
  
  }

  go_delete(){
    // let ref = firebase.database().ref('acc').child('plans/'+this.project_delete_data.key)
    this.Ref_data.child(this.project_delete_data.key).remove().then(()=>{
     
    this.toastr.success('The project removed successfully')
    
    }).then(()=>{
      var desertRef = firebase.storage().ref('photoslibrary').child(this.project_delete_data.img_name)
      desertRef.delete().then(() => {
       
        let id = "close_window"
        let go = document.getElementById(id)
        go.click()
          this.get_photoslibrary()
        let dsData = this.dataSource.data;

        const itemIndex = dsData.findIndex(obj => obj[this.idColumn] === this.project_delete_data.key);
        this.dataSource.data.splice(itemIndex, 1);
        this.dataSource.paginator = this.paginator;
        this.project_delete_data=null

          this.toastr.success(' Deleted' )
      })
   
    })
  }

  copyToClipboard(event) {
    let url =event.vid_url
    const elem = document.createElement('textarea');
    elem.value = url;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    this.toastr.success(' The url of Vedio" '+ event.vid_title+' " is copied' )
 }
}


export interface PeriodicElement {
  cid:number,vehicle_type:string,Make:string,Model:string,date:number,Engine:string,ECU:string,Credits:number, }
 export class ButtonOverviewExample {}
 const ELEMENT_DATA: PeriodicElement[] = [
  //  {    cid:1,vehicle_type:'car',Make:'bmw',Model:'m3',date:343432423,Engine:'ppr433e',ECU:'siemens',Credits:10},
 
];


function comparenameup(a, b) {
  const genreA = a.date;
  const genreB = b.date;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison* -1;
}