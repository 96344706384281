import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.css']
})
export class StreamingComponent implements OnInit {
  spinner:boolean=false
  images0:boolean=false
  url00
  url0:string
  image_name
  data_check:boolean=false
  time_now
  uploadProgress0
  uploadProgress_0
  program_data={date:0,name_en:null,name_ar:null,program:null,
    subtitle_en:null,subtitle_ar:null, 
    body_en:null,body_ar:null,
    vid_url:null,
    live_url:null,
     }
  constructor(private modalService: NgbModal,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.get_Event()
    this.read_data()
    
  }
  read_data(){
  
    
    let ref_test = firebase.database().ref('stream')
    ref_test.once('value',snapshot=>{
      if(snapshot.exists()){
        this.program_data=snapshot.val()
      }
    }).catch(error=>{
      console.log(error.message)
    })
  }
  init_upload(){
    if(this.program_data.program ){
        let id = "uploading_window"
      let go = document.getElementById(id)
      go.click()
        
      }else{
        this.toastr.error('You should input all necessary data')
        this.data_check=true
      }
  }

 
  upload(){
    
    this.time_now = Date.now()
    this.program_data.date=this.time_now
    
    let ref_test = firebase.database().ref('stream')

    ref_test.set(this.program_data).then(()=>{
      this.toastr.success('Upload finished, The Data added successfully. ')
      let id = "close_window"
      let go = document.getElementById(id)
      go.click()
    // this.router.navigate(['admin/blogs/list'])
   
    

    })
    
  
    
   
  
  }
  


  closeResult
  open(content, type, modalDimension) {
    console.log(content, type, modalDimension)
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }else if (modalDimension === 'xl' ) {
      
      this.modalService.open(content, {  size: 'lg'}).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
  }else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }


  Event_list=[]
  get_Event(){
    this.Event_list=['online','offline','no_stream']
  //   this.spinner=true
  //   firebase.database().ref('program').once('value',snapshots=>{
  //   snapshots.forEach(snapshot=>{
  // let y = snapshot.val()
  // var date1 = new Date(y.date);
  // y.date=  date1.toLocaleString()
  
  // y.key = snapshot.key
  // this.Event_list.push(y)
  //   })
  // })
  }
  init_add_vid(){
    let id = "add_vid_performance_0"
    let go = document.getElementById(id)
    go.click()
  
  }
 
}
