import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { LanguagesService } from '../services/languages.service';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-join-us-view',
  templateUrl: './join-us-view.component.html',
  styleUrls: ['./join-us-view.component.css']
})
export class JoinUsViewComponent implements OnInit {
  title
  id1
  show:boolean=false
  jobs_data={date:0,name_en:null,name_tr:null,name_ar:null,
    description_en:null,description_tr:null,description_ar:null,
    start_date:null,end_date:null,start_date_t:null,end_date_t:null,location:null,
    body_en:null,body_tr:null,body_ar:null,
    }
  constructor(private router: Router,protected sanitizer: DomSanitizer,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.title=this.route.snapshot.params['id']
    this.id1=this.route.snapshot.queryParamMap.get('id')
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.get_jobs()
    this.get_lang()
  
  }
  langstatus
  dir
  language
 get_lang(){
  this.langstatus=localStorage.getItem('language')
  this.dir=localStorage.getItem('dir')
  this.language=LanguagesService.language.join_us

}
  get_jobs(){
    if(this.title){
  // let key = VariableService.ecu_service.key
  let ref = firebase.database().ref('jobs').child(this.id1)
      ref.once('value',snapshots=>{

        if(snapshots.exists()){
         
            this.jobs_data=snapshots.val()
            this.jobs_data.body_en= this.transform(this.jobs_data.body_en)
            this.jobs_data.body_tr= this.transform(this.jobs_data.body_tr)
            this.jobs_data.body_ar= this.transform(this.jobs_data.body_ar)
           
          
       
        
        }else{
          this.router.navigate(['/jobs'])
            }
      
      }).then(()=>{
        this.get_other_jobs()
        this.show=true
      })
    }
  }
jobs_list=[]
get_other_jobs(){
  this.jobs_list=[]
let  Ref_data = firebase.database().ref('jobs').orderByKey().limitToLast(4)

Ref_data.once('value',snapshots=>{
  snapshots.forEach(snapshot=>{
let y = snapshot.val()
if(y.name_en != this.jobs_data.name_en){
  var date1 = new Date(y.date);
  y.date=  date1.toLocaleDateString()
  
  y.key = snapshot.key
  this.jobs_list.push(y)
}

  })
})
}

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  read_more(event){
    // VariablesService.project=event
     
    this.router.navigate(['join-us/'+event.name_en])

  }
 
}
