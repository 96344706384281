import { Component, OnInit } from '@angular/core';
import  firebase from "firebase/app";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-volunteering',
  templateUrl: './admin-volunteering.component.html',
  styleUrls: ['./admin-volunteering.component.css']
})
export class AdminVolunteeringComponent implements OnInit {
  data={en_description:null,ar_description:null}
    ref=firebase.database().ref("volunteering")

  constructor(private toastr: ToastrService) {
    this.read()
   }

  ngOnInit(): void {
  
  }
  read(){
    this.ref.once('value',snapshot=>{
      if(snapshot.exists()){
        this.data=snapshot.val()
      }
 
    })
  }
  save(){

    this.ref.set(this.data).catch(error=>{
 
      this.toastr.success(error.message)
    }).then(()=>{
      this.toastr.success('Data update successfully')
    })
  }
}
