import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  email;password;email_reset
  btn:boolean=false
  constructor(private toastr: ToastrService,private router : Router,private modalService: NgbModal) { }

  ngOnInit() {
  }
  async  login(){
      console.log(this.email)
    try {
      await  firebase.auth().signInWithEmailAndPassword(this.email,this.password).then(()=>{
      
        this.toastr.success("You have signed in successfully")
      
   
        this.router.navigate(['/admin'])
      })
      ////console.log("Anonymous account successfully login");
     
      
      
    
  } catch (error) {
     // alert("Error!"  +  e.message);
     this.toastr.error(error.message )
  }
  }
  async resetpas(){
  
    
      firebase.auth().sendPasswordResetEmail(this.email_reset).catch(error=>{
        this.toastr.error(error.message)

      }).then(()=>{
        let id = "close_window"
        let go = document.getElementById(id)
        go.click()
        this.toastr.success('The link of reset password sended to your email')
       
        this.btn=false
      })
    
  }
  closeResult
  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
        this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
          this.closeResult = 'Closed with: $result';
      }, (reason) => {
          this.closeResult = 'Dismissed $this.getDismissReason(reason)';
      });
    } else {
        this.modalService.open(content,{ centered: true }).result.then((result) => {
            this.closeResult = 'Closed with: $result';
        }, (reason) => {
            this.closeResult = 'Dismissed $this.getDismissReason(reason)';
        });
    }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  'with: $reason';
      }
  }
}
